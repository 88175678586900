import { profileSettings, user, gallery } from '../actions'
import axios from '../cgAxios'
import { manageProfileMedia } from 'redux/actions/thunks/util/manageProfileMedia'

const saveUnit = (settings) => {
  return function (dispatch) {
    dispatch(profileSettings.unitSave())
    dispatch(user.settingsSave())

    axios({
      method: 'put',
      url: '/api/user',
      data: settings,
    })
      .then((response) => {
        dispatch(profileSettings.unitResponse())
        dispatch(user.settingsResponse(response.data.settings))
      })
      .catch((error) => {
        dispatch(profileSettings.unitFailed(error))
        dispatch(user.settingsFailure(error))
      })
  }
}

const saveLanguage = (settings) => {
  return async (dispatch) => {
    dispatch(profileSettings.languageSave())
    dispatch(user.settingsSave())

    let request = axios({
      method: 'put',
      url: '/api/user',
      data: settings,
    })
      .then((response) => {
        dispatch(profileSettings.languageResponse())
        dispatch(user.settingsResponse(response.data.settings))
      })
      .catch((error) => {
        dispatch(profileSettings.languageFailed(error))
        dispatch(user.settingsFailure(error))
      })

    return request
  }
}

const saveProfilePicture = (file, oldMedia) => (dispatch) => {
  dispatch(profileSettings.pictureSave())

  manageProfileMedia(file, oldMedia, (media, error) => {
    if (error) return dispatch(profileSettings.pictureFailed(error))

    const _media = {
      media: [...media],
    }

    return axios({
      method: 'put',
      url: '/api/user',
      data: _media,
    })
      .then((response) => {
        dispatch(profileSettings.pictureResponse())
        dispatch(user.authorized(response.data))
      })
      .catch((error) => {
        dispatch(profileSettings.pictureFailed(error))
      })
  })
}

const saveTerms = (accepted) => {
  return async (dispatch) => {
    dispatch(profileSettings.termsSave())
    dispatch(user.termsSave())


    let request = axios({
      method: 'put',
      url: '/api/user',
      data: { webToSAccepted: accepted },
    })
      .then((response) => {
        dispatch(profileSettings.termsResponse())
        dispatch(user.termsResponse(response.data.webToSAccepted))
      })
      .catch((error) => {
        dispatch(profileSettings.termsFailed(error))
        dispatch(user.termsFailure(error))
      })

    return request
  }
}

const saveWebsettings = (webSettings) => {
  return function (dispatch) {
    dispatch(profileSettings.ptEmailNotificationSave())
    dispatch(user.webSettingsSave())

    axios({
      method: 'put',
      url: '/api/user',
      data: webSettings,
    })
      .then((response) => {
        dispatch(profileSettings.ptEmailNotificationResponse())
        dispatch(user.webSettingsResponse(response.data.webSettings))
      })
      .catch((error) => {
        dispatch(profileSettings.ptEmailNotificationFailed(error))
        dispatch(user.webSettingsFailure(error))
      })
  }
}

const getSitesToCopyTo = function () {
  return function (dispatch) {
    dispatch(user.getCopyToSites())
    dispatch(profileSettings.getCopyToSites())
    axios({ method: 'get', url: `/api/user/sites` })
      .then(response => {
        dispatch(user.getCopyToSitesResponse(response.data))
        dispatch(profileSettings.getCopyToSitesResponse())
      })
      .catch(error => {
        console.log('getSitesToCopyTo error', error)
        dispatch(user.getCopyToSitesFailure(error))
        dispatch(profileSettings.getCopyToSitesFailed())
      })
  }
}

const getGallery = function () {
  return function (dispatch) {
    dispatch(gallery.get())
    axios({ method: 'get', url: `/api/advagym/gallery` })
      .then(response => {
        dispatch(gallery.response(response.data))
      })
      .catch(error => {
        console.log('getGallery error', error)
        dispatch(gallery.fail(error))
      })
  }
}

const setContentLang = function (lang) {
  return function (dispatch) {
    dispatch(user.setContentLang(lang))
  }
}

const resetContentLang = function () {
  return function (dispatch) {
    dispatch(user.resetContentLang())
  }
}

export default {
  saveUnit,
  saveLanguage,
  saveProfilePicture,
  saveTerms,
  saveWebsettings,
  getSitesToCopyTo,
  setContentLang,
  resetContentLang,
  getGallery,
}
