import i18n from 'i18next'
// import { reactI18nextModule } from 'react-i18next'
import detector from 'i18next-browser-languagedetector'

import translation_de from './locale/de/'
import translation_en from './locale/en/'
import translation_es from './locale/es/'
import translation_fr from './locale/fr/'
import translation_it from './locale/it/'
import translation_pt from './locale/pt/'
import translation_sv from './locale/sv/'
import translation_ja from './locale/ja/'
import translation_nl from './locale/nl/'

export const resources = {
  es: {
    translation: translation_es,
  },
  de: {
    translation: translation_de,
  },
  en: {
    translation: translation_en,
  },
  fr: {
    translation: translation_fr,
  },
  it: {
    translation: translation_it,
  },
  pt: {
    translation: translation_pt,
  },
  ja: {
    translation: translation_ja,
  },
  sv: {
    translation: translation_sv,
  },
  nl: {
    translation: translation_nl,
  },
}

export const supportedLanguages = [
  {
    flag: 'de',
    code: 'de',
    language: 'Deutsch',
  },
  {
    flag: 'gb',
    code: 'en',
    language: 'English',
  },
  {
    flag: 'es',
    code: 'es',
    language: 'Español',
  },
  {
    flag: 'fr',
    code: 'fr',
    language: 'Français',
  },
  {
    flag: 'it',
    code: 'it',
    language: 'Italiana',
  },
  {
    flag: 'nl',
    code: 'nl',
    language: 'Nederlands',
  },
  {
    flag: 'pt',
    code: 'pt',
    language: 'Portoghese',
  },
  {
    flag: 'se',
    code: 'sv',
    language: 'Svenska',
  },
  {
    flag: 'jp',
    code: 'ja',
    language: '日本語',
  },
]

const whitelist = Object.keys(resources)

i18n
  .use(detector)
  // .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    whitelist,
    fallbackLng: 'en',
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
