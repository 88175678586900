import * as types from './actiontypes'

export const storeReset = () => {
  return { type: types.STORE_RESET }
}

export const join = {
  request: function () {
    return { type: types.join.REQUEST }
  },
  response: function (status, message) {
    return { type: types.join.RESPONSE, status, message }
  },
  showWelcomeScreen: function () {
    return { type: types.join.SHOW_WELCOME_SCREEN }
  },
  hideWelcomeScreen: function () {
    return { type: types.join.HIDE_WELCOME_SCREEN }
  },
  showFormStatus: function (formMessage, formMessageType) {
    return { type: types.join.SHOW_FORM_STATUS, formMessage, formMessageType }
  },
  hideFormStatus: function () {
    return { type: types.join.HIDE_FORM_STATUS }
  },
  clearForm: function () {
    return { type: types.join.CLEAR_FORM }
  },
  onInput: function () {
    return { type: types.join.ON_INPUT }
  },
  reset: function () {
    return { type: types.join.RESET }
  },
}

export const signIn = {
  request: function () {
    return { type: types.signIn.REQUEST }
  },
  response: function (status, message) {
    return { type: types.signIn.RESPONSE, status, message }
  },
  showWelcomeScreen: function () {
    return { type: types.signIn.SHOW_WELCOME_SCREEN }
  },
  hideWelcomeScreen: function () {
    return { type: types.signIn.HIDE_WELCOME_SCREEN }
  },
  showFormStatus: function (formMessage, formMessageType) {
    return { type: types.signIn.SHOW_FORM_STATUS, formMessage, formMessageType }
  },
  hideFormStatus: function () {
    return { type: types.signIn.HIDE_FORM_STATUS }
  },
  clearForm: function () {
    return { type: types.signIn.CLEAR_FORM }
  },
  onInput: function () {
    return { type: types.signIn.ON_INPUT }
  },
  reset: function () {
    return { type: types.signIn.RESET }
  },
}

export const passwordForgotten = {
  request: function () {
    return { type: types.passwordForgotten.REQUEST }
  },
  response: function (status, message) {
    return { type: types.passwordForgotten.RESPONSE, status, message }
  },
  showWelcomeScreen: function () {
    return { type: types.passwordForgotten.SHOW_WELCOME_SCREEN }
  },
  hideWelcomeScreen: function () {
    return { type: types.passwordForgotten.HIDE_WELCOME_SCREEN }
  },
  showFormStatus: function (formMessage, formMessageType) {
    return {
      type: types.passwordForgotten.SHOW_FORM_STATUS,
      formMessage,
      formMessageType,
    }
  },
  hideFormStatus: function () {
    return { type: types.passwordForgotten.HIDE_FORM_STATUS }
  },
  clearForm: function () {
    return { type: types.passwordForgotten.CLEAR_FORM }
  },
  onInput: function () {
    return { type: types.passwordForgotten.ON_INPUT }
  },
  reset: function () {
    return { type: types.passwordForgotten.RESET }
  },
}

export const passwordChange = {
  request: function () {
    return { type: types.passwordChange.REQUEST }
  },
  response: function (status, message) {
    return { type: types.passwordChange.RESPONSE, status, message }
  },
  showWelcomeScreen: function () {
    return { type: types.passwordChange.SHOW_WELCOME_SCREEN }
  },
  hideWelcomeScreen: function () {
    return { type: types.passwordChange.HIDE_WELCOME_SCREEN }
  },
  showFormStatus: function (formMessage, formMessageType) {
    return {
      type: types.passwordChange.SHOW_FORM_STATUS,
      formMessage,
      formMessageType,
    }
  },
  hideFormStatus: function () {
    return { type: types.passwordChange.HIDE_FORM_STATUS }
  },
  clearForm: function () {
    return { type: types.passwordChange.CLEAR_FORM }
  },
  onInput: function () {
    return { type: types.passwordChange.ON_INPUT }
  },
  reset: function () {
    return { type: types.passwordChange.RESET }
  },
}

export const user = {
  posted: () => {
    return { type: types.user.POSTED }
  },
  deAuthorize: () => {
    return { type: types.user.DEAUTHORIZE }
  },
  authorized: (data) => {
    return { type: types.user.AUTHORIZED, data }
  },
  unAuthorized: (status, message) => {
    return { type: types.user.UNAUTHORIZED, status, message }
  },
  settingsSave: () => {
    return { type: types.user.SETTINGS_UNIT_SAVE }
  },
  settingsResponse: (settings) => {
    return { type: types.user.SETTINGS_UNIT_RESPONSE, settings }
  },
  settingsFailure: (error) => {
    return { type: types.user.SETTINGS_UNIT_FAILURE, error }
  },
  termsSave: () => {
    return { type: types.user.TERMS_SAVE }
  },
  termsResponse: (accepted) => {
    return { type: types.user.TERMS_RESPONSE, accepted }
  },
  termsFailure: (error) => {
    return { type: types.user.TERMS_FAILURE, error }
  },
  webSettingsSave: () => {
    return { type: types.user.WEB_SETTINGS_SAVE }
  },
  webSettingsResponse: (settings) => {
    return { type: types.user.WEB_SETTINGS_RESPONSE, settings }
  },
  webSettingsFailure: (error) => {
    return { type: types.user.WEB_SETTINGS_FAILURE, error }
  },
  getCopyToSites: () => {
    return { type: types.user.GET_COPY_TO_SITES }
  },
  getCopyToSitesResponse: (sites) => {
    return { type: types.user.GET_COPY_TO_SITES_RESPONSE, sites }
  },
  getCopyToSitesFailure: (error) => {
    return { type: types.user.GET_COPY_TO_SITES_FAILURE, error }
  },
  setContentLang: (lang) => {
    return { type: types.user.SET_CONTENT_LANG, lang }
  },
  resetContentLang: () => {
    return { type: types.user.RESET_CONTENT_LANG }
  },
  getCopyStatus: (status, sites) => {
    return { type: types.user.GET_COPY_STATUS, status, sites }
  },
  getUnreadSiteMessages: (unreadSiteMessages) => {
    return { type: types.user.GET_UNREAD_SITE_MESSAGES, unreadSiteMessages }
  },
}

export const signOut = {
  requested: function () {
    return { type: types.signOut.REQUESTED }
  },
  success: function () {
    return { type: types.signOut.SUCCESS }
  },
  failed: function (status, message) {
    return { type: types.signOut.FAILED, status, message }
  },
}

export const menu = {
  open: function () {
    return { type: types.menu.OPEN }
  },
  close: function () {
    return { type: types.menu.CLOSE }
  },
  toggle: function () {
    return { type: types.menu.TOGGLE }
  },
}

export const users = {
  get: function () {
    return { type: types.users.GET }
  },
  response: function (response) {
    return { type: types.users.RESPONSE, users: response.data }
  },
  getFailed: function (error) {
    return { type: types.users.GET_FAIL, error }
  },
}

export const sites = {
  get: function () {
    return { type: types.sites.GET }
  },
  response: function (count, sites) {
    return { type: types.sites.RESPONSE, count, sites }
  },
  getFailed: function (error) {
    return { type: types.sites.GET_FAIL, error }
  },
  searchForSites: function () {
    return { type: types.sites.SEARCH }
  },
  searchForSitesResponse: function (sites) {
    return { type: types.sites.SEARCH_RESPONSE, sites }
  },
  searchForSitesFailed: function (error) {
    return { type: types.sites.SEARCH_FAILED, error }
  },
  searchForSitesClear: function () {
    return { type: types.sites.SEARCH_CLEAR }
  },
}

export const exercises = {
  get: function () {
    return { type: types.exercises.GET }
  },
  response: function (response) {
    return { type: types.exercises.RESPONSE, exercises: response.data }
  },
  getFailed: function (error) {
    return { type: types.exercises.GET_FAIL, error }
  },
}

export const exercise = {
  get: function (id, what) {
    return { type: types.exercise.GET, id, what }
  },
  save: function (id, exercise, what) {
    return { type: types.exercise.SAVE, id, exercise, what }
  },
  delete: function (id, what) {
    return { type: types.exercise.DELETE, id, what }
  },
  response: function (id, exercises) {
    return { type: types.exercise.RESPONSE, id, exercises: exercises }
  },
  saveResponse: function (id, exercises) {
    return { type: types.exercise.SAVE_RESPONSE, id, exercises: exercises }
  },
  deleteResponse: function (id) {
    return { type: types.exercise.DELETE_RESPONSE, id }
  },
  getFailed: function (id, error) {
    return { type: types.exercise.GET_FAIL, id, error }
  },
  saveFailed: function (id, error) {
    return { type: types.exercise.SAVE_FAIL, id, error }
  },
  deleteFailed: function (id, error) {
    return { type: types.exercise.DELETE_FAIL, id, error }
  },
}

export const myGymInfo = {
  get: () => ({
    type: types.myGymInfo.GET,
  }),
  getResponse: (response) => ({
    type: types.myGymInfo.GET_RESPONSE,
    gym: response,
  }),
  getFailed: (error) => ({
    type: types.myGymInfo.GET_FAILED,
    error,
  }),
  save: () => ({
    type: types.myGymInfo.SAVE,
  }),
  saveResponse: (response, isBeacon) => ({
    type: types.myGymInfo.SAVE_RESPONSE,
    gym: response,
    isBeacon,
  }),
  saveFailed: (error) => ({
    type: types.myGymInfo.SAVE_FAILED,
    error,
  }),
  clear: () => ({
    type: types.myGymInfo.CLEAR,
  }),
  pictureFailed: (error) => ({
    type: types.myGymInfo.GYM_LOGO_ERROR,
    error,
  }),
  saveBeaconMessage: (message) => ({
    type: types.myGymInfo.SAVE_BEACON_MESSAGE,
    message,
  }),
  saveBeaconMessageResponse: (message) => ({
    type: types.myGymInfo.SAVE_BEACON_MESSAGE_RESPONSE,
    message,
  }),
  saveBeaconMessageFailed: (error) => ({
    type: types.myGymInfo.SAVE_BEACON_MESSAGE_FAILED,
    error,
  }),
}

export const myGymInvitation = {
  get: () => ({
    type: types.myGymInvitation.GET,
  }),
  getResponse: (response) => ({
    type: types.myGymInvitation.GET_RESPONSE,
    gymInvite: response,
  }),
  getFailed: (error) => ({
    type: types.myGymInvitation.GET_FAILED,
    error,
  }),
  getSite: () => ({
    type: types.myGymInvitation.GET_SITE,
  }),
  getSiteResponse: (response) => ({
    type: types.myGymInvitation.GET_SITE_RESPONSE,
    site: response,
  }),
  getSiteFailed: (error) => ({
    type: types.myGymInvitation.GET_SITE_FAILED,
    error,
  }),
  generateNew: () => ({
    type: types.myGymInvitation.GENERATE_NEW,
  }),
  generateNewResponse: (response) => ({
    type: types.myGymInvitation.GENERATE_NEW_RESPONSE,
    gymInvite: response,
  }),
  generateNewFailed: (error) => ({
    type: types.myGymInvitation.GENERATE_NEW_FAILED,
    error,
  }),
}

export const gymZones = {
  get: () => ({
    type: types.gymZones.GET,
  }),
  response: (id, machines, routines, exercises) => ({
    type: types.gymZones.RESPONSE,
    machines,
    id,
    routines,
    exercises,
  }),
  getFailed: (error) => ({
    type: types.gymZones.GET_FAIL,
    error,
  }),

  create: (siteId, machine) => ({ type: types.gymZones.CREATE, machine }),
  createResponse: (machine) => ({
    type: types.gymZones.CREATE_RESPONSE,

    machine,
  }),
  createFailed: (error) => ({ type: types.gymZones.CREATE_FAILED, error }),
  clear: () => ({ type: types.gymZones.CLEAR }),
  filterOutZone: (machineId) => ({
    type: types.gymZones.FILTER_OUT_ZONE,
    machineId,
  }),
  import: function () {
    return { type: types.gymZones.IMPORT }
  },
  importResponse: function () {
    return { type: types.gymZones.IMPORT_RESPONSE }
  },
  importFailed: function (error) {
    return { type: types.gymZones.IMPORT_FAILED, error }
  },
}

export const gymZone = {
  get: function (id, what) {
    return { type: types.gymZone.GET, id, what }
  },
  save: function (id, zone, what) {
    return { type: types.gymZone.SAVE, id, zone, what }
  },
  delete: function (id, what) {
    return { type: types.gymZone.DELETE, id, what }
  },

  response: function (id, zones, exercises) {
    return { type: types.gymZone.RESPONSE, id, zones: zones, exercises }
  },
  saveResponse: function (id, zones) {
    return { type: types.gymZone.SAVE_RESPONSE, id, zones: zones }
  },
  deleteResponse: function (id) {
    return { type: types.gymZone.DELETE_RESPONSE, id }
  },

  getFailed: function (id, error) {
    return { type: types.gymZone.GET_FAIL, id, error }
  },
  saveFailed: function (id, error) {
    return { type: types.gymZone.SAVE_FAIL, id, error }
  },
  deleteFailed: function (id, error) {
    return { type: types.gymZone.DELETE_FAIL, id, error }
  },

  setMode: function (mode) {
    return { type: types.gymZone.SET_MODE, mode }
  },

  sendCopyZone: function (site, zone, percentage, what) {
    return {
      type: types.gymZone.SENDCOPY,
      site,
      zone,
      percentage,
      what,
    }
  },
  sendCopyResponse: function (result, percentage, what) {
    return {
      type: types.gymZone.SENDCOPY_RESPONSE,
      result,
      percentage,
      what,
    }
  },
  sendCopyZoneComplete: function () {
    return { type: types.gymZone.SENDCOPY_COMPLETE }
  },

  addExercises: function (exercises) {
    return { type: types.gymZone.ADD_EXERCISES, exercises }
  },
  removeExercise: function (exercise) {
    return { type: types.gymZone.REMOVE_EXERCISE, exercise }
  },
  clearExercises: function () {
    return { type: types.gymZone.CLEAR_EXERCISES }
  },
}

export const gymMachines = {
  get: () => ({
    type: types.gymMachines.GET,
  }),
  response: (id, machines) => ({
    type: types.gymMachines.RESPONSE,
    machines,
  }),
  getFailed: (error) => ({
    type: types.gymMachines.GET_FAIL,
    error,
  }),

  create: (siteId, machine) => ({ type: types.gymMachines.CREATE, machine }),
  createResponse: (machine) => ({
    type: types.gymMachines.CREATE_RESPONSE,

    machine,
  }),
  createFailed: (error) => ({ type: types.gymMachines.CREATE_FAILED, error }),
  clear: () => ({ type: types.gymMachines.CLEAR }),

  work: function (id, machine, site, percentage, what) {
    return {
      type: types.gymMachines.WORK,
      id,
      machine,
      site,
      percentage,
      what,
    }
  },
  workResponse: function (id, site, percentage, result, what) {
    return {
      type: types.gymMachines.WORK_RESPONSE,
      id,
      site,
      percentage,
      result,
      what,
    }
  },
  clearWork: function () {
    return { type: types.gymMachines.CLEAR_WORK }
  },
  filterOutMachine: function (machine) {
    return { type: types.gymMachines.FILTER_OUT_MACHINE, machine }
  },
}

export const gymMachine = {
  get: (id, what) => ({ type: types.gymMachine.GET, id, what }),
  save: (id, exercise, what) => ({
    type: types.gymMachine.SAVE,
    id,
    exercise,
    what,
  }),
  response: (machine) => ({ type: types.gymMachine.RESPONSE, machine }),
  saveResponse: (machine) => ({
    type: types.gymMachine.SAVE_RESPONSE,
    machine,
  }),
  getFailed: (id, error) => ({ type: types.gymMachine.GET_FAIL, id, error }),
  saveFailed: (id, error) => ({ type: types.gymMachine.SAVE_FAIL, id, error }),

  remove: (machineId, what) => ({
    type: types.gymMachine.REMOVE,
    machineId,
    what,
  }),
  removeResponse: (machine, what) => ({
    type: types.gymMachine.REMOVE_RESPONSE,
    machine,
    what,
  }),
  removeFailed: (machineId, what) => ({
    type: types.gymMachine.REMOVE_FAILED,
    machineId,
    what,
  }),
  setMode: (mode) => ({ type: types.gymMachine.SET_MODE, mode }),
}

export const categories = {
  get: function () {
    return { type: types.categories.GET }
  },
  response: function (response) {
    return { type: types.categories.RESPONSE, categories: response.data }
  },
  getFailed: function (error) {
    return { type: types.categories.GET_FAIL, error }
  },
}

export const category = {
  get: function (id, what) {
    return { type: types.category.GET, id, what }
  },
  save: function (id, category, what) {
    return { type: types.category.SAVE, id, category, what }
  },
  delete: function (id, what) {
    return { type: types.category.DELETE, id, what }
  },
  response: function (id, categories) {
    return { type: types.category.RESPONSE, id, categories: categories }
  },
  saveResponse: function (id, categories) {
    return { type: types.category.SAVE_RESPONSE, id, categories: categories }
  },
  deleteResponse: function (id) {
    return { type: types.category.DELETE_RESPONSE, id }
  },
  getFailed: function (id, error) {
    return { type: types.category.GET_FAIL, id, error }
  },
  saveFailed: function (id, error) {
    return { type: types.category.SAVE_FAIL, id, error }
  },
  deleteFailed: function (id, error) {
    return { type: types.category.DELETE_FAIL, id, error }
  },
}

export const collections = {
  get: function () {
    return { type: types.collections.GET }
  },
  response: function (response) {
    return { type: types.collections.RESPONSE, collections: response.data }
  },
  getFailed: function (error) {
    return { type: types.collections.GET_FAIL, error }
  },
}

export const collection = {
  get: function (id, what) {
    return { type: types.collection.GET, id, what }
  },
  save: function (id, collection, what) {
    return { type: types.collection.SAVE, id, collection, what }
  },
  delete: function (id, what) {
    return { type: types.collection.DELETE, id, what }
  },
  response: function (id, collections) {
    return { type: types.collection.RESPONSE, id, collections: collections }
  },
  saveResponse: function (id, collections) {
    return {
      type: types.collection.SAVE_RESPONSE,
      id,
      collections: collections,
    }
  },
  deleteResponse: function (id) {
    return { type: types.collection.DELETE_RESPONSE, id }
  },
  getFailed: function (id, error) {
    return { type: types.collection.GET_FAIL, id, error }
  },
  saveFailed: function (id, error) {
    return { type: types.collection.SAVE_FAIL, id, error }
  },
  deleteFailed: function (id, error) {
    return { type: types.collection.DELETE_FAIL, id, error }
  },
}

export const programs = {
  get: function () {
    return { type: types.programs.GET }
  },
  response: function (response) {
    return { type: types.programs.RESPONSE, programs: response.data }
  },
  getFailed: function (error) {
    return { type: types.programs.GET_FAIL, error }
  },
}

export const program = {
  get: function (id, what) {
    return { type: types.program.GET, id, what }
  },
  save: function (id, program, what) {
    return { type: types.program.SAVE, id, program, what }
  },
  delete: function (id, what) {
    return { type: types.program.DELETE, id, what }
  },
  response: function (id, programs) {
    return { type: types.program.RESPONSE, id, programs: programs }
  },
  saveResponse: function (id, programs) {
    return { type: types.program.SAVE_RESPONSE, id, programs: programs }
  },
  deleteResponse: function (id) {
    return { type: types.program.DELETE_RESPONSE, id }
  },
  getFailed: function (id, error) {
    return { type: types.program.GET_FAIL, id, error }
  },
  saveFailed: function (id, error) {
    return { type: types.program.SAVE_FAIL, id, error }
  },
  deleteFailed: function (id, error) {
    return { type: types.program.DELETE_FAIL, id, error }
  },
}

export const gym = {
  get: function (id, what) {
    return { type: types.gym.GET, id, what }
  },
  save: function (id, gym, what) {
    return { type: types.gym.SAVE, id, gym, what }
  },
  delete: function (id, what) {
    return { type: types.gym.DELETE, id, what }
  },
  response: function (id, gyms) {
    return { type: types.gym.RESPONSE, id, gyms: gyms }
  },
  saveResponse: function (id, gyms) {
    return { type: types.gym.SAVE_RESPONSE, id, gyms: gyms }
  },
  deleteResponse: function (id) {
    return { type: types.gym.DELETE_RESPONSE, id }
  },
  getFailed: function (id, error) {
    return { type: types.gym.GET_FAIL, id, error }
  },
  saveFailed: function (id, error) {
    return { type: types.gym.SAVE_FAIL, id, error }
  },
  deleteFailed: function (id, error) {
    return { type: types.gym.DELETE_FAIL, id, error }
  },
}

export const machine = {
  get: function (id, what) {
    return { type: types.machine.GET, id, what }
  },
  save: function (id, machine, what) {
    return { type: types.machine.SAVE, id, machine, what }
  },
  delete: function (id, what) {
    return { type: types.machine.DELETE, id, what }
  },
  response: function (id, machines) {
    return { type: types.machine.RESPONSE, id, machines: machines }
  },
  saveResponse: function (id, machines) {
    return { type: types.machine.SAVE_RESPONSE, id, machines: machines }
  },
  deleteResponse: function (id) {
    return { type: types.machine.DELETE_RESPONSE, id }
  },
  getFailed: function (id, error) {
    return { type: types.machine.GET_FAIL, id, error }
  },
  saveFailed: function (id, error) {
    return { type: types.machine.SAVE_FAIL, id, error }
  },
  deleteFailed: function (id, error) {
    return { type: types.machine.DELETE_FAIL, id, error }
  },
  setMode: function (mode) {
    return { type: types.machine.SET_MODE, mode }
  },
}

export const observer = {
  get: function (id, what) {
    return { type: types.observer.GET, id, what }
  },
  save: function (id, observer, what) {
    return { type: types.observer.SAVE, id, observer, what }
  },
  delete: function (id, what) {
    return { type: types.observer.DELETE, id, what }
  },
  response: function (id, observers) {
    return { type: types.observer.RESPONSE, id, observers: observers }
  },
  saveResponse: function (id, observers) {
    return { type: types.observer.SAVE_RESPONSE, id, observers: observers }
  },
  deleteResponse: function (id) {
    return { type: types.observer.DELETE_RESPONSE, id }
  },
  getFailed: function (id, error) {
    return { type: types.observer.GET_FAIL, id, error }
  },
  saveFailed: function (id, error) {
    return { type: types.observer.SAVE_FAIL, id, error }
  },
  deleteFailed: function (id, error) {
    return { type: types.observer.DELETE_FAIL, id, error }
  },
}

export const stackCopy = {
  set: function (id, stack) {
    return { type: types.stackCopy.SET, id, stack }
  },
  remove: function (id) {
    return { type: types.stackCopy.REMOVE, id }
  },
}

export const myWorkout = {
  get: function () {
    return { type: types.myWorkout.GET }
  },
  response: function (workout) {
    return { type: types.myWorkout.RESPONSE, workout }
  },
  getFailed: function (error) {
    return { type: types.myWorkout.GET_FAIL, error }
  },
  clear: function () {
    return { type: types.myWorkout.CLEAR }
  },
}

export const myPrograms = {
  get: () => ({
    type: types.myPrograms.GET,
  }),
  response: (response) => ({
    type: types.myPrograms.RESPONSE,
    programs: response.data,
  }),
  getFailed: (error) => ({
    type: types.myPrograms.GET_FAIL,
    error,
  }),
  filterOutProgram: (program) => ({
    type: types.myPrograms.FILTER_OUT_WORKOUT,
    program,
  }),
}

export const myClientMessages = {
  getMessages: () => ({
    type: types.myClientMessages.GET_MESSAGES,
  }),
  getMessagesResponse: (response, limit) => ({
    type: types.myClientMessages.GET_MESSAGES_RESPONSE,
    messages: response,
    limit,
  }),
  getMessagesFailed: (error) => ({
    type: types.myClientMessages.GET_MESSAGES_FAIL,
    error,
  }),

  getMoreMessages: () => ({
    type: types.myClientMessages.GET_MORE_MESSAGES,
  }),
  getMoreMessagesResponse: (response) => ({
    type: types.myClientMessages.GET_MORE_MESSAGES_RESPONSE,
    messages: response,
  }),
  getMoreMessagesFailed: (error) => ({
    type: types.myClientMessages.GET_MORE_MESSAGES_FAIL,
    error,
  }),

  sendMessage: (response) => ({
    type: types.myClientMessages.SEND_MESSAGE,
    message: response,
  }),
  sendMessageResponse: (response) => ({
    type: types.myClientMessages.SEND_MESSAGE_RESPONSE,
    message: response,
  }),
  sendMessageFailed: (error) => ({
    type: types.myClientMessages.SEND_MESSAGE_FAIL,
    error,
  }),

  removeMessage: () => ({
    type: types.myClientMessages.REMOVE_MESSAGE,
  }),
  removeMessageResponse: (response) => ({
    type: types.myClientMessages.REMOVE_MESSAGE_RESPONSE,
    message: response,
  }),
  removeMessageFailed: (error) => ({
    type: types.myClientMessages.REMOVE_MESSAGE_FAIL,
    error,
  }),

  getLiveMessagesResponse: (response) => ({
    type: types.myClientMessages.GET_LIVE_MESSAGES_RESPONSE,
    messages: response,
  }),
}

export const myClientGroupMessages = {
  getMessages: () => ({
    type: types.myClientGroupMessages.GET_MESSAGES,
  }),
  getMessagesResponse: (response) => ({
    type: types.myClientGroupMessages.GET_MESSAGES_RESPONSE,
    messages: response,
  }),
  getMessagesFailed: (error) => ({
    type: types.myClientGroupMessages.GET_MESSAGES_FAIL,
    error,
  }),

  getMoreMessages: () => ({
    type: types.myClientGroupMessages.GET_MORE_MESSAGES,
  }),
  getMoreMessagesResponse: (response) => ({
    type: types.myClientGroupMessages.GET_MORE_MESSAGES_RESPONSE,
    messages: response,
  }),
  getMoreMessagesFailed: (error) => ({
    type: types.myClientGroupMessages.GET_MORE_MESSAGES_FAIL,
    error,
  }),

  sendMessage: (response) => ({
    type: types.myClientGroupMessages.SEND_MESSAGE,
    message: response,
  }),
  sendMessageResponse: (response) => ({
    type: types.myClientGroupMessages.SEND_MESSAGE_RESPONSE,
    message: response,
  }),
  sendMessageFailed: (error) => ({
    type: types.myClientGroupMessages.SEND_MESSAGE_FAIL,
    error,
  }),

  removeMessage: () => ({
    type: types.myClientGroupMessages.REMOVE_MESSAGE,
  }),
  removeMessageResponse: (response) => ({
    type: types.myClientGroupMessages.REMOVE_MESSAGE_RESPONSE,
    message: response,
  }),
  removeMessageFailed: (error) => ({
    type: types.myClientGroupMessages.REMOVE_MESSAGE_FAIL,
    error,
  }),
}

export const myClientGroups = {
  create: () => ({
    type: types.myClientGroups.CREATE,
  }),
  createResponse: (response) => ({
    type: types.myClientGroups.CREATE_RESPONSE,
    group: response,
  }),
  createFailed: (error) => ({
    type: types.myClientGroups.CREATE_FAIL,
    error,
  }),
  pictureFailed: (error) => ({
    type: types.myClientGroups.LOGO_ERROR,
    error,
  }),

  get: function () {
    return { type: types.myClientGroups.GET }
  },
  response: function (responseData) {
    return { type: types.myClientGroups.RESPONSE, groups: responseData }
  },
  getFailed: function (error) {
    return { type: types.myClientGroups.GET_FAIL, error }
  },
  cancelSet: function (email) {
    return { type: types.myClientGroups.CANCEL_SET, email }
  },
  cancelClear: function () {
    return { type: types.myClientGroups.CANCEL_CLEAR }
  },

  getMoreGroups: function () {
    return { type: types.myClientGroups.GET_MORE_CLIENT_GROUPS }
  },
  getMoreGroupsResponse: function (groups) {
    return {
      type: types.myClientGroups.GET_MORE_CLIENT_GROUPS_RESPONSE,
      groups,
    }
  },
  getMoreGroupsFailed: function (groups, error) {
    return {
      type: types.myClientGroups.GET_MORE_CLIENT_GROUPS_FAILED,
      groups,
      error,
    }
  },

  searchForGroups: function () {
    return { type: types.myClientGroups.SEARCH_FOR_CLIENT_GROUPS }
  },
  searchForGroupsResponse: function (groups) {
    return {
      type: types.myClientGroups.SEARCH_FOR_CLIENT_GROUPS_RESPONSE,
      groups,
    }
  },
  searchForGroupsFailed: function (groups, error) {
    return {
      type: types.myClientGroups.SEARCH_FOR_CLIENT_GROUPS_FAILED,
      groups,
      error,
    }
  },
  searchForGroupsClear: function () {
    return { type: types.myClientGroups.SEARCH_FOR_CLIENT_GROUPS_CLEAR }
  },

  getUnreadMessagesResponse: function (unreadMessages) {
    return {
      type: types.myClientGroups.GET_ALL_UNREAD_MESSAGES_RESPONSE,
      unreadMessages,
    }
  },

  getClientCount: function () {
    return { type: types.myClientGroups.GET_CLIENT_GROUPS_COUNT }
  },
  getClientCountResponse: function (groups) {
    return {
      type: types.myClientGroups.GET_CLIENT_GROUPS_COUNT_RESPONSE,
      groups,
    }
  },
  getClientCountFailed: function (groups, error) {
    return {
      type: types.myClientGroups.GET_CLIENT_GROUPS_COUNT_FAILED,
      groups,
      error,
    }
  },
}

export const myClientGroup = {
  get: () => ({
    type: types.myClientGroup.GET,
  }),
  getResponse: (response) => ({
    type: types.myClientGroup.GET_RESPONSE,
    group: response,
  }),
  getFailed: (error) => ({
    type: types.myClientGroup.GET_FAIL,
    error,
  }),
  save: () => ({
    type: types.myClientGroup.SAVE,
  }),
  saveResponse: (response) => ({
    type: types.myClientGroup.SAVE_RESPONSE,
    group: response,
  }),
  saveFailed: (error) => ({
    type: types.myClientGroup.SAVE_FAIL,
    error,
  }),
  addMembers: () => ({
    type: types.myClientGroup.ADD_MEMBERS,
  }),
  addMembersResponse: (clients) => ({
    type: types.myClientGroup.ADD_MEMBERS_RESPONSE,
    clients: clients,
  }),
  addMembersFailed: (error) => ({
    type: types.myClientGroup.ADD_MEMBERS_FAIL,
    error,
  }),
  removeClient: () => ({
    type: types.myClientGroup.REMOVE_CLIENT,
  }),
  removeClientResponse: (response) => ({
    type: types.myClientGroup.REMOVE_CLIENT_RESPONSE,
    client: response,
  }),
  removeClientFailed: (error) => ({
    type: types.myClientGroup.REMOVE_CLIENT_FAIL,
    error,
  }),
  remove: () => ({
    type: types.myClientGroup.REMOVE,
  }),
  removeResponse: (response) => ({
    type: types.myClientGroup.REMOVE_RESPONSE,
    group: response,
  }),
  removeFailed: (error) => ({
    type: types.myClientGroup.REMOVE_FAIL,
    error,
  }),
  clear: () => ({
    type: types.myClientGroup.CLEAR,
  }),
  pictureFailed: (error) => ({
    type: types.myClientGroup.LOGO_ERROR,
    error,
  }),
  messageAddProgram: (routine, clear) => ({
    type: types.myClientGroup.MESSAGE_ADD_WORKOUT,
    routine: routine,
    clear,
  }),
  progressClear: function () {
    return { type: types.myClientGroup.PROGRESS_CLEAR }
  },
  // ======= WORKOUTs ==========//
  saveProgram: function (programId) {
    return { type: types.myClientGroup.SAVE_WORKOUT, programId }
  },
  saveProgramResponse: function (program) {
    return { type: types.myClientGroup.SAVE_WORKOUT_RESPONSE, program }
  },
  saveProgramFailed: function (error, programId) {
    return {
      type: types.myClientGroup.SAVE_WORKOUT_FAIL,
      error,
      programId,
    }
  },
  removeProgram: function (programId) {
    return { type: types.myClientGroup.REMOVE_WORKOUT, programId }
  },
  removeProgramResponse: function (programId) {
    return { type: types.myClientGroup.REMOVE_WORKOUT_RESPONSE, programId }
  },
  removeProgramFailed: function (error, programId) {
    return {
      type: types.myClientGroup.REMOVE_WORKOUT_FAIL,
      error,
      programId,
    }
  },
  // ======= NOTES ==========//
  getNotes: function () {
    return { type: types.myClientGroup.GET_NOTES }
  },
  getNotesResponse: function (notes) {
    return {
      type: types.myClientGroup.GET_NOTES_RESPONSE,
      notes,
    }
  },
  getNotesFailed: function (notes, error) {
    return { type: types.myClientGroup.GET_NOTES_FAILED, notes, error }
  },

  saveNote: function (note) {
    return { type: types.myClientGroup.SAVE_NOTE, note }
  },
  saveNoteResponse: function (note) {
    return { type: types.myClientGroup.SAVE_NOTE_RESPONSE, note }
  },
  saveNoteFailed: function (note, error) {
    return { type: types.myClientGroup.SAVE_NOTE_FAILED, note, error }
  },

  removeNote: function (note) {
    return { type: types.myClientGroup.REMOVE_NOTE, note }
  },
  removeNoteResponse: function (note) {
    return {
      type: types.myClientGroup.REMOVE_NOTE_RESPONSE,
      note,
    }
  },
  removeNoteFailed: function (note, error) {
    return {
      type: types.myClientGroup.REMOVE_NOTE_FAILED,
      note,
      error,
    }
  },

  createNote: function (note) {
    return { type: types.myClientGroup.CREATE_NOTE, note }
  },
  createNoteResponse: function (note) {
    return { type: types.myClientGroup.CREATE_NOTE_RESPONSE, note }
  },
  createNoteFailed: function (note, error) {
    return { type: types.myClientGroup.CREATE_NOTE_FAILED, note, error }
  },
}

export const myProgram = {
  create: function () {
    return { type: types.myProgram.CREATE }
  },
  get: function (id, what) {
    return { type: types.myProgram.GET, id, what }
  },
  save: function (id, program, what) {
    return { type: types.myProgram.SAVE, id, program, what }
  },
  duplicate: function (id, program, what) {
    return { type: types.myProgram.DUPLICATE, id, program, what }
  },
  delete: function (id, what) {
    return { type: types.myProgram.DELETE, id, what }
  },
  share: function (id, what) {
    return { type: types.myProgram.SHARE, id, what }
  },
  response: function (id, programs) {
    return { type: types.myProgram.RESPONSE, id, programs: programs }
  },
  createResponse: function (program) {
    return { type: types.myProgram.CREATE_RESPONSE, program: program }
  },
  saveResponse: function (id, programs) {
    return { type: types.myProgram.SAVE_RESPONSE, id, programs: programs }
  },
  copyResponse: function (id, programs) {
    return { type: types.myProgram.COPY_RESPONSE, id, programs }
  },
  duplicateResponse: function (id, programs) {
    return { type: types.myProgram.DUPLICATE_RESPONSE, id, programs }
  },
  deleteResponse: function (id) {
    return { type: types.myProgram.DELETE_RESPONSE, id }
  },
  shareResponse: function (id, share) {
    return { type: types.myProgram.SHARE_RESPONSE, id, share }
  },
  getFailed: function (id, error) {
    return { type: types.myProgram.GET_FAIL, id, error }
  },
  createFailed: function (error) {
    return { type: types.myProgram.CREATE_FAIL, error }
  },
  saveFailed: function (id, error) {
    return { type: types.myProgram.SAVE_FAIL, id, error }
  },
  duplicateFailed: function (id, error) {
    return { type: types.myProgram.DUPLICATE_FAIL, id, error }
  },
  deleteFailed: function (id, error) {
    return { type: types.myProgram.DELETE_FAIL, id, error }
  },
  shareFailed: function (id, error) {
    return { type: types.myProgram.SHARE_FAIL, id, error }
  },
  dismissSharingDialog: function () {
    return { type: types.myProgram.DISMISS_SHARING_DIALOG }
  },
  dismissDuplicateDialog: function () {
    return { type: types.myProgram.DISMISS_DUPLICATE_DIALOG }
  },
  setMode: function (mode) {
    return { type: types.myProgram.SET_MODE, mode }
  },
  clear: function () {
    return { type: types.myProgram.CLEAR }
  },
}

export const myExercises = {
  get: function () {
    return { type: types.myExercises.GET }
  },
  response: function (exercises) {
    return { type: types.myExercises.RESPONSE, exercises: exercises.data }
  },
  getFailed: function (error) {
    return { type: types.myExercises.GET_FAIL, error }
  },
  addNew: function (exercise) {
    return { type: types.myExercises.ADD_NEW, exercise }
  },

  getExercises: function () {
    return { type: types.myExercises.GET_EXERCISES }
  },
  getExercisesResponse: function (exercises, count) {
    return {
      type: types.myExercises.GET_EXERCISES_RESPONSE,
      exercises,
      count,
    }
  },
  getExercisesFailed: function (exercises, error) {
    return { type: types.myExercises.GET_EXERCISES_FAILED, exercises, error }
  },
  searchForExercises: function () {
    return { type: types.myExercises.SEARCH_FOR_EXERCISES }
  },
  searchForExercisesResponse: function (exercises) {
    return {
      type: types.myExercises.SEARCH_FOR_EXERCISES_RESPONSE,
      exercises,
    }
  },
  searchForExercisesFailed: function (exercises, error) {
    return {
      type: types.myExercises.SEARCH_FOR_EXERCISES_FAILED,
      exercises,
      error,
    }
  },
  searchForExercisesClear: function () {
    return { type: types.myExercises.SEARCH_FOR_EXERCISES_CLEAR }
  },
}

export const myProgramShare = {
  get: function (id, what) {
    return { type: types.myProgramShare.GET, id, what }
  },
  remove: function (id, what) {
    return { type: types.myProgramShare.DELETE, id, what }
  },
  response: function (id, share) {
    return { type: types.myProgramShare.RESPONSE, id, share: share }
  },
  removeResponse: function (id) {
    return { type: types.myProgramShare.DELETE_RESPONSE, id }
  },
  getFailed: function (id, error) {
    return { type: types.myProgramShare.GET_FAIL, id, error }
  },
  removeFailed: function (id, error) {
    return { type: types.myProgramShare.DELETE_FAIL, id, error }
  },
  setMode: function (mode) {
    return { type: types.myProgramShare.SET_MODE, mode }
  },
  updateMessage: function (id, what) {
    return { type: types.myProgramShare.UPDATE_MESSAGE, id, what }
  },
  updateMessageResponse: function (id, share) {
    return { type: types.myProgramShare.UPDATE_MESSAGE_RESPONSE, id, share }
  },
  updateMessageFailed: function (id, error) {
    return { type: types.myProgramShare.UPDATE_MESSAGE_FAIL, id, error }
  },
}

export const myPrivateExercises = {
  get: function () {
    return { type: types.myPrivateExercises.GET }
  },
  response: function (response) {
    return { type: types.myPrivateExercises.RESPONSE, exercises: response.data }
  },
  getFailed: function (error) {
    return { type: types.myPrivateExercises.GET_FAIL, error }
  },
}

export const myPrivateExercise = {
  clear: function () {
    return { type: types.myPrivateExercise.CLEAR }
  },
  clearError: function () {
    return { type: types.myPrivateExercise.CLEAR_ERROR }
  },

  create: function (id, what) {
    return { type: types.myPrivateExercise.CREATE, id, what }
  },
  createResponse: function (exercise) {
    return { type: types.myPrivateExercise.CREATE_RESPONSE, exercise }
  },
  createFailed: function (exercise, error) {
    return { type: types.myPrivateExercise.CREATE_FAIL, exercise, error }
  },
  get: function (id, what) {
    return { type: types.myPrivateExercise.GET, id, what }
  },
  save: function (id, exercise, what) {
    return { type: types.myPrivateExercise.SAVE, id, exercise, what }
  },
  delete: function (id, what) {
    return { type: types.myPrivateExercise.DELETE, id, what }
  },
  response: function (id, exercises) {
    return { type: types.myPrivateExercise.RESPONSE, id, exercises: exercises }
  },
  saveResponse: function (id, exercises) {
    return {
      type: types.myPrivateExercise.SAVE_RESPONSE,
      id,
      exercises: exercises,
    }
  },
  deleteResponse: function (id) {
    return { type: types.myPrivateExercise.DELETE_RESPONSE, id }
  },
  getFailed: function (id, error) {
    return { type: types.myPrivateExercise.GET_FAIL, id, error }
  },
  saveFailed: function (id, error) {
    return { type: types.myPrivateExercise.SAVE_FAIL, id, error }
  },
  deleteFailed: function (id, error) {
    return { type: types.myPrivateExercise.DELETE_FAIL, id, error }
  },
  setMode: function (mode) {
    return { type: types.myPrivateExercise.SET_MODE, mode }
  },
}

export const myGymPrograms = {
  get: function () {
    return { type: types.myGymPrograms.GET }
  },
  response: function (programs) {
    return { type: types.myGymPrograms.RESPONSE, programs }
  },
  getFailed: function (error) {
    return { type: types.myGymPrograms.GET_FAIL, error }
  },
  updateVisibility: function (updatedList) {
    return {
      type: types.myGymPrograms.UPDATE_VISIBILITY,
      updatedList: updatedList,
    }
  },
  create: function () {
    return { type: types.myGymPrograms.CREATE }
  },
  createResponse: function (data) {
    return { type: types.myGymPrograms.CREATE_RESPONSE, data }
  },
  createFailed: function (error) {
    return { type: types.myGymPrograms.CREATE_FAIL, error }
  },
  clearCreate: function () {
    return { type: types.myGymPrograms.CLEAR_CREATE }
  },
  filterOutProgram: function (program) {
    return { type: types.myGymPrograms.FILTER_OUT_WORKOUT, program }
  },
  copy: function () {
    return { type: types.myGymPrograms.COPY }
  },
  copyResponse: function () {
    return { type: types.myGymPrograms.COPY_RESPONSE }
  },
  copyFailed: function (error) {
    return { type: types.myGymPrograms.COPY_FAILED, error }
  },
  import: function () {
    return { type: types.myGymPrograms.IMPORT }
  },
  importResponse: function () {
    return { type: types.myGymPrograms.IMPORT_RESPONSE }
  },
  importFailed: function (error) {
    return { type: types.myGymPrograms.IMPORT_FAILED, error }
  },
}

export const myGymExercises = {
  get: function () {
    return { type: types.myGymExercises.GET }
  },
  response: function (exercises) {
    return { type: types.myGymExercises.RESPONSE, exercises: exercises }
  },
  getFailed: function (error) {
    return { type: types.myGymExercises.GET_FAIL, error }
  },
  addNew: function (exercise) {
    return { type: types.myGymExercises.ADD_NEW, exercise }
  },

  getExercises: function (scope) {
    return { type: types.myGymExercises.GET_EXERCISES, scope }
  },
  getExercisesResponse: function (exercises, count, scope) {
    return {
      type: types.myGymExercises.GET_EXERCISES_RESPONSE,
      exercises,
      count,
      scope,
    }
  },
  getExercisesFailed: function (exercises, error) {
    return { type: types.myGymExercises.GET_EXERCISES_FAILED, exercises, error }
  },

  searchForExercises: function (scope) {
    return { type: types.myGymExercises.SEARCH_FOR_EXERCISES, scope }
  },
  searchForExercisesResponse: function (exercises, scope) {
    return {
      type: types.myGymExercises.SEARCH_FOR_EXERCISES_RESPONSE,
      exercises,
      scope,
    }
  },
  searchForExercisesFailed: function (exercises, error) {
    return {
      type: types.myGymExercises.SEARCH_FOR_EXERCISES_FAILED,
      exercises,
      error,
    }
  },
  searchForExercisesClear: function () {
    return { type: types.myGymExercises.SEARCH_FOR_EXERCISES_CLEAR }
  },
  filterOutExercise: function (exercise) {
    return { type: types.myGymExercises.FILTER_OUT_EXERCISE, exercise }
  },
  getExercisesCountResponse: function (count, scope) {
    return { type: types.myGymExercises.SILENT_GET_COUNT, count, scope }
  },
}

export const myGymProgram = {
  get: function (id, what) {
    return { type: types.myGymProgram.GET, id, what }
  },
  save: function (id, program, what) {
    return { type: types.myGymProgram.SAVE, id, program, what }
  },
  delete: function (id, what) {
    return { type: types.myGymProgram.DELETE, id, what }
  },
  duplicate: function (id, program, what) {
    return { type: types.myGymProgram.DUPLICATE, id, program, what }
  },
  sendCopy: function (id, program, what) {
    return { type: types.myGymProgram.SENDCOPY, id, program, what }
  },
  response: function (id, programs) {
    return { type: types.myGymProgram.RESPONSE, id, programs: programs }
  },
  saveResponse: function (id, programs) {
    return { type: types.myGymProgram.SAVE_RESPONSE, id, programs: programs }
  },
  deleteResponse: function (id) {
    return { type: types.myGymProgram.DELETE_RESPONSE, id }
  },
  copyResponse: function (id, programs) {
    return { type: types.myGymProgram.COPY_RESPONSE, id, programs }
  },
  duplicateResponse: function (id, programs) {
    return { type: types.myGymProgram.DUPLICATE_RESPONSE, id, programs }
  },
  getFailed: function (id, error) {
    return { type: types.myGymProgram.GET_FAIL, id, error }
  },
  saveFailed: function (id, error) {
    return { type: types.myGymProgram.SAVE_FAIL, id, error }
  },
  deleteFailed: function (id, error) {
    return { type: types.myGymProgram.DELETE_FAIL, id, error }
  },
  duplicateFailed: function (id, error) {
    return { type: types.myGymProgram.DUPLICATE_FAIL, id, error }
  },
  dismissDuplicateDialog: function () {
    return { type: types.myGymProgram.DISMISS_DUPLICATE_DIALOG }
  },
  setMode: function (mode) {
    return { type: types.myGymProgram.SET_MODE, mode }
  },

  sendCopyProgram: function (site, program, percentage, what) {
    return {
      type: types.myGymProgram.SENDCOPY,
      site,
      program,
      percentage,
      what,
    }
  },
  sendCopyResponse: function (result, percentage, what) {
    return {
      type: types.myGymProgram.SENDCOPY_RESPONSE,
      result,
      percentage,
      what,
    }
  },
  sendCopyProgramComplete: function () {
    return { type: types.myGymProgram.SENDCOPY_COMPLETE }
  },
}

export const myGymExercise = {
  clear: function () {
    return { type: types.myGymExercise.CLEAR }
  },
  clearError: function () {
    return { type: types.myGymExercise.CLEAR_ERROR }
  },

  create: function (id, what) {
    return { type: types.myGymExercise.CREATE, id, what }
  },
  createResponse: function (exercise) {
    return { type: types.myGymExercise.CREATE_RESPONSE, exercise }
  },
  createFailed: function (exercise, error) {
    return { type: types.myGymExercise.CREATE_FAIL, exercise, error }
  },
  get: function (id, what) {
    return { type: types.myGymExercise.GET, id, what }
  },
  save: function (id, exercise, what) {
    return { type: types.myGymExercise.SAVE, id, exercise, what }
  },
  delete: function (id, what) {
    return { type: types.myGymExercise.DELETE, id, what }
  },
  response: function (id, exercises) {
    return { type: types.myGymExercise.RESPONSE, id, exercises: exercises }
  },
  saveResponse: function (id, exercises) {
    return { type: types.myGymExercise.SAVE_RESPONSE, id, exercises: exercises }
  },
  deleteResponse: function (id) {
    return { type: types.myGymExercise.DELETE_RESPONSE, id }
  },
  getFailed: function (id, error) {
    return { type: types.myGymExercise.GET_FAIL, id, error }
  },
  saveFailed: function (id, error) {
    return { type: types.myGymExercise.SAVE_FAIL, id, error }
  },
  deleteFailed: function (id, error) {
    return { type: types.myGymExercise.DELETE_FAIL, id, error }
  },
  setMode: function (mode) {
    return { type: types.myGymExercise.SET_MODE, mode }
  },

  work: function (id, exercise, site, percentage, what) {
    return {
      type: types.myGymExercise.WORK,
      id,
      exercise,
      site,
      percentage,
      what,
    }
  },
  workResponse: function (id, site, percentage, result, what) {
    return {
      type: types.myGymExercise.WORK_RESPONSE,
      id,
      site,
      percentage,
      result,
      what,
    }
  },

  copy: function () {
    return { type: types.myGymExercise.COPY }
  },
  copyResponse: function () {
    return { type: types.myGymExercise.COPY_RESPONSE }
  },
  copyFailed: function (error) {
    return { type: types.myGymExercise.COPY_FAILED, error }
  },

  clearWork: function () {
    return { type: types.myGymExercise.CLEAR_WORK }
  },
}

export const myGymMessages = {
  getMessages: () => ({
    type: types.myGymMessages.GET_MESSAGES,
  }),
  getMessagesResponse: (response) => ({
    type: types.myGymMessages.GET_MESSAGES_RESPONSE,
    messages: response,
  }),
  getMessagesFailed: (error) => ({
    type: types.myGymMessages.GET_MESSAGES_FAIL,
    error,
  }),

  getMoreMessages: () => ({
    type: types.myGymMessages.GET_MORE_MESSAGES,
  }),
  getMoreMessagesResponse: (response) => ({
    type: types.myGymMessages.GET_MORE_MESSAGES_RESPONSE,
    messages: response,
  }),
  getMoreMessagesFailed: (error) => ({
    type: types.myGymMessages.GET_MORE_MESSAGES_FAIL,
    error,
  }),

  sendMessage: (response) => ({
    type: types.myGymMessages.SEND_MESSAGE,
    message: response,
  }),
  sendMessageResponse: (response) => ({
    type: types.myGymMessages.SEND_MESSAGE_RESPONSE,
    message: response,
  }),
  sendMessageFailed: (error) => ({
    type: types.myGymMessages.SEND_MESSAGE_FAIL,
    error,
  }),

  removeMessage: () => ({
    type: types.myGymMessages.REMOVE_MESSAGE,
  }),
  removeMessageResponse: (response) => ({
    type: types.myGymMessages.REMOVE_MESSAGE_RESPONSE,
    message: response,
  }),
  removeMessageFailed: (error) => ({
    type: types.myGymMessages.REMOVE_MESSAGE_FAIL,
    error,
  }),
}

export const myGymMemberActivity = {
  getActivity: () => ({
    type: types.myGymMemberActivity.GET_ACTIVITY,
  }),
  getActivityResponse: (response) => ({
    type: types.myGymMemberActivity.GET_ACTIVITY_RESPONSE,
    activity: response,
  }),
  getActivityFailed: (error) => ({
    type: types.myGymMemberActivity.GET_ACTIVITY_FAIL,
    error,
  }),
}

export const managePermissions = {
  getUsers: function (permission) {
    return { type: types.managePermissions.GET_USERS, permission }
  },
  getUsersResponse: function (permission, users) {
    return {
      type: types.managePermissions.GET_USERS_RESPONSE,
      permission,
      users,
    }
  },
  getUsersFailed: function (permission, error) {
    return { type: types.managePermissions.GET_USERS_FAILED, permission, error }
  },

  addPermission: function (permission) {
    return { type: types.managePermissions.ADD_PERMISSION, permission }
  },
  addPermissionResponse: function (permission) {
    return { type: types.managePermissions.ADD_PERMISSION_RESPONSE, permission }
  },
  addPermissionFailed: function (permission, error) {
    return {
      type: types.managePermissions.ADD_PERMISSION_FAILED,
      permission,
      error,
    }
  },

  removePermission: function (permission) {
    return { type: types.managePermissions.REMOVE_PERMISSION, permission }
  },
  removePermissionResponse: function (permission) {
    return {
      type: types.managePermissions.REMOVE_PERMISSION_RESPONSE,
      permission,
    }
  },
  removePermissionFailed: function (permission, error) {
    return {
      type: types.managePermissions.REMOVE_PERMISSION_FAILED,
      permission,
      error,
    }
  },

  clearErrors: function (permission) {
    return { type: types.managePermissions.CLEAR_ERRORS, permission }
  },
}

export const importCSV = {
  getEmails: function () {
    return { type: types.importCSV.GET }
  },
  getEmailsResponse: function (emails) {
    return { type: types.importCSV.GET_RESPONSE, emails }
  },
  getEmailsFail: function (error) {
    return { type: types.importCSV.GET_FAIL, error }
  },
  clearEmails: function () {
    return { type: types.importCSV.CLEAR }
  },
}

export const manageMembers = {
  getNumberOfMembers: function () {
    return { type: types.manageMembers.GET_NUMBER_OF_MEMBERS }
  },
  getNumberOfMembersResponse: function (members) {
    return {
      type: types.manageMembers.GET_NUMBER_OF_MEMBERS_RESPONSE,
      members,
    }
  },
  getNumberOfMembersFailed: function (members, error) {
    return {
      type: types.manageMembers.GET_NUMBER_OF_MEMBERS_FAILED,
      members,
      error,
    }
  },

  getMembers: function (date) {
    return { type: types.manageMembers.GET_MEMBERS, date }
  },
  getMembersResponse: function (members, date) {
    return {
      type: types.manageMembers.GET_MEMBERS_RESPONSE,
      members,
      date,
    }
  },
  getMembersFailed: function (members, error) {
    return { type: types.manageMembers.GET_MEMBERS_FAILED, members, error }
  },

  searchForMembers: function (date) {
    return { type: types.manageMembers.SEARCH_FOR_MEMBERS, date }
  },
  searchForMembersResponse: function (members, date) {
    return {
      type: types.manageMembers.SEARCH_FOR_MEMBERS_RESPONSE,
      members,
      date,
    }
  },
  searchForMembersFailed: function (members, error) {
    return {
      type: types.manageMembers.SEARCH_FOR_MEMBERS_FAILED,
      members,
      error,
    }
  },
  searchForMembersClear: function () {
    return { type: types.manageMembers.SEARCH_FOR_MEMBERS_CLEAR }
  },

  saveMemberResponse: function (member) {
    return {
      type: types.manageMembers.SAVE_MEMBER_RESPONSE,
      member,
    }
  },
  removeMemberResponse: function (member) {
    return {
      type: types.manageMembers.REMOVE_MEMBER_RESPONSE,
      member,
    }
  },

  addMembers: function () {
    return { type: types.manageMembers.ADD_MEMBERS }
  },
  addMembersResponse: function (members) {
    return {
      type: types.manageMembers.ADD_MEMBERS_RESPONSE,
      members,
    }
  },
  addMembersFailed: function (members, error) {
    return { type: types.manageMembers.ADD_MEMBERS_FAILED, members, error }
  },

  updateMembers: function () {
    return { type: types.manageMembers.UPDATE_MEMBERS }
  },
  updateMembersResponse: function () {
    return {
      type: types.manageMembers.UPDATE_MEMBERS_RESPONSE,
    }
  },
  updateMembersFailed: function (error) {
    return { type: types.manageMembers.UPDATE_MEMBERS_FAILED, error }
  },

  clearErrors: function () {
    return { type: types.manageMembers.CLEAR_ERRORS }
  },
  setFilter: function (filter) {
    return { type: types.manageMembers.SET_FILTER, filter }
  },

  work: function (id, percentage, what) {
    return {
      type: types.manageMembers.WORK,
      id,
      percentage,
      what,
    }
  },
  workResponse: function (id, percentage, result, what) {
    return {
      type: types.manageMembers.WORK_RESPONSE,
      id,
      percentage,
      result,
      what,
    }
  },
  clearWork: function () {
    return { type: types.manageMembers.CLEAR_WORK }
  },
}

export const manageMember = {
  getMember: function () {
    return { type: types.manageMember.GET_MEMBER }
  },
  getMemberResponse: function (member) {
    return {
      type: types.manageMember.GET_MEMBER_RESPONSE,
      member,
    }
  },
  getMemberFailed: function (member, error) {
    return { type: types.manageMember.GET_MEMBER_FAILED, member, error }
  },

  saveMember: function (member) {
    return { type: types.manageMember.SAVE_MEMBER, member }
  },
  saveMemberResponse: function (data) {
    return { type: types.manageMember.SAVE_MEMBER_RESPONSE, data }
  },
  saveMemberFailed: function (member, error) {
    return { type: types.manageMember.SAVE_MEMBER_FAILED, member, error }
  },

  removeMember: function (member) {
    return { type: types.manageMember.REMOVE_MEMBER, member }
  },
  removeMemberResponse: function (member) {
    return {
      type: types.manageMember.REMOVE_MEMBER_RESPONSE,
      member,
    }
  },
  removeMemberStatusResponse: function (member) {
    return {
      type: types.manageMember.REMOVE_MEMBER_STATUS_RESPONSE,
      member,
    }
  },
  removeMemberFailed: function (member, error) {
    return {
      type: types.manageMember.REMOVE_MEMBER_FAILED,
      member,
      error,
    }
  },
  // ======= NOTES ==========//
  getMemberNotes: function () {
    return { type: types.manageMember.GET_NOTES }
  },
  getMemberNotesResponse: function (notes) {
    return {
      type: types.manageMember.GET_NOTES_RESPONSE,
      notes,
    }
  },
  getMemberNotesFailed: function (notes, error) {
    return { type: types.manageMember.GET_NOTES_FAILED, notes, error }
  },

  saveMemberNote: function (note) {
    return { type: types.manageMember.SAVE_NOTE, note }
  },
  saveMemberNoteResponse: function (note) {
    return { type: types.manageMember.SAVE_NOTE_RESPONSE, note }
  },
  saveMemberNoteFailed: function (note, error) {
    return { type: types.manageMember.SAVE_NOTE_FAILED, note, error }
  },

  removeNote: function (note) {
    return { type: types.manageMember.REMOVE_NOTE, note }
  },
  removeNoteResponse: function (note) {
    return {
      type: types.manageMember.REMOVE_NOTE_RESPONSE,
      note,
    }
  },
  removeNoteFailed: function (note, error) {
    return {
      type: types.manageMember.REMOVE_NOTE_FAILED,
      note,
      error,
    }
  },

  createMemberNote: function (note) {
    return { type: types.manageMember.CREATE_NOTE, note }
  },
  createMemberNoteResponse: function (note) {
    return { type: types.manageMember.CREATE_NOTE_RESPONSE, note }
  },
  createMemberNoteFailed: function (note, error) {
    return { type: types.manageMember.CREATE_NOTE_FAILED, note, error }
  },
  // ======= WORKOUTS ==========//
  getPrograms: function () {
    return { type: types.manageMember.GET_WORKOUTS }
  },
  getProgramsResponse: function (programs) {
    return {
      type: types.manageMember.GET_WORKOUTS_RESPONSE,
      programs,
    }
  },
  getProgramsFailed: function (programs, error) {
    return { type: types.manageMember.GET_WORKOUTS_FAILED, programs, error }
  },

  saveProgram: function (program) {
    return { type: types.manageMember.SAVE_WORKOUT, program }
  },
  saveProgramResponse: function (program) {
    return { type: types.manageMember.SAVE_WORKOUT_RESPONSE, program }
  },
  saveProgramFailed: function (error) {
    return { type: types.manageMember.SAVE_WORKOUT_FAILED, error }
  },

  removeProgram: function (program) {
    return { type: types.manageMember.REMOVE_WORKOUT, program }
  },
  removeProgramResponse: function (program) {
    return {
      type: types.manageMember.REMOVE_WORKOUT_RESPONSE,
      program,
    }
  },
  removeProgramFailed: function (program, error) {
    return {
      type: types.manageMember.REMOVE_WORKOUT_FAILED,
      program,
      error,
    }
  },

  createProgram: function (program) {
    return { type: types.manageMember.CREATE_WORKOUT, program }
  },
  createProgramResponse: function (program, clear) {
    return { type: types.manageMember.CREATE_WORKOUT_RESPONSE, program, clear }
  },
  createProgramFailed: function (error) {
    return { type: types.manageMember.CREATE_WORKOUT_FAILED, error }
  },

  progressClear: function () {
    return { type: types.manageMember.PROGRESS_CLEAR }
  },

  //=========== CLEAR =======//
  clear: function () {
    return { type: types.manageMember.CLEAR }
  },
  clearErrors: function () {
    return { type: types.manageMember.CLEAR_ERRORS }
  },
  setUnread: function (unreadMessages) {
    return { type: types.manageMember.SET_UNREAD_MESSAGES, unreadMessages }
  },
}

export const manageSuPermissions = {
  getUsers: function () {
    return { type: types.manageSuPermissions.GET_USERS }
  },
  getUsersResponse: function (users) {
    return { type: types.manageSuPermissions.GET_USERS_RESPONSE, users }
  },
  getUsersFailed: function (error) {
    return { type: types.manageSuPermissions.GET_USERS_FAILED, error }
  },

  addPermission: function () {
    return { type: types.manageSuPermissions.ADD_PERMISSION }
  },
  addPermissionResponse: function () {
    return { type: types.manageSuPermissions.ADD_PERMISSION_RESPONSE }
  },
  addPermissionFailed: function (error) {
    return { type: types.manageSuPermissions.ADD_PERMISSION_FAILED, error }
  },

  removePermission: function () {
    return { type: types.manageSuPermissions.REMOVE_PERMISSION }
  },
  removePermissionResponse: function () {
    return { type: types.manageSuPermissions.REMOVE_PERMISSION_RESPONSE }
  },
  removePermissionFailed: function (error) {
    return { type: types.manageSuPermissions.REMOVE_PERMISSION_FAILED, error }
  },

  clearErrors: function () {
    return { type: types.manageSuPermissions.CLEAR_ERRORS }
  },
}

export const clientWorkouts = {
  get: function (id) {
    return { type: types.clientWorkouts.GET, id: id }
  },
  getResponse: function (id, clientWorkouts) {
    return { type: types.clientWorkouts.RESPONSE, id: id, clientWorkouts }
  },
  getFailed: function (id, errorMessage) {
    return { type: types.clientWorkouts.GET_FAILED, id: id, errorMessage }
  },
  getMore: function (id) {
    return { type: types.clientWorkouts.GET_MORE, id: id }
  },
  getMoreResponse: function (id, clientWorkouts) {
    return {
      type: types.clientWorkouts.GET_MORE_RESPONSE,
      id: id,
      clientWorkouts,
    }
  },
  getMoreFailed: function (id, errorMessage) {
    return { type: types.clientWorkouts.GET_MORE_FAILED, id: id, errorMessage }
  },
}

export const memberWorkouts = {
  get: function (id) {
    return { type: types.memberWorkouts.GET, id: id }
  },
  getResponse: function (id, memberWorkouts) {
    return { type: types.memberWorkouts.RESPONSE, id: id, memberWorkouts }
  },
  getFailed: function (id, errorMessage) {
    return { type: types.memberWorkouts.GET_FAILED, id: id, errorMessage }
  },
  getMore: function (id) {
    return { type: types.memberWorkouts.GET_MORE, id: id }
  },
  getMoreResponse: function (id, memberWorkouts) {
    return {
      type: types.memberWorkouts.GET_MORE_RESPONSE,
      id: id,
      memberWorkouts,
    }
  },
  getMoreFailed: function (id, errorMessage) {
    return { type: types.memberWorkouts.GET_MORE_FAILED, id: id, errorMessage }
  },
}

export const gymStatus = {
  get: function (id) {
    return { type: types.gymStatus.GET, id, what: 'fetching' }
  },
  response: function (id, gym) {
    return { type: types.gymStatus.GET_RESPONSE, id, gym }
  },
  getFail: function (id, error) {
    return { type: types.gymStatus.GET_FAIL, id, error }
  },
  clear: function () {
    return { type: types.gymStatus.CLEAR }
  },
}

export const gymBannerStatus = {
  get: function () {
    return { type: types.gymBannerStatus.GET }
  },
  response: function (sites) {
    return { type: types.gymBannerStatus.GET_RESPONSE, sites }
  },
  getFail: function (error) {
    return { type: types.gymBannerStatus.GET_FAIL, error }
  },
  clear: function () {
    return { type: types.gymBannerStatus.CLEAR }
  },
}

export const gymUtilization = {
  get: (id, timeStamp) => ({
    siteId: id,
    lastRequestDateMachine: timeStamp,
    type: types.gymUtilization.GET,
  }),
  calculating: () => ({
    type: types.gymUtilization.CALCULATING,
  }),
  response: (data, timeStamp) => ({
    type: types.gymUtilization.RESPONSE,
    lastRequestDateMachine: timeStamp,
    data,
  }),
  failure: (error) => ({
    type: types.gymUtilization.FAILURE,
    error,
  }),
  selectMachine: (id) => ({
    type: types.gymUtilization.SELECT_MACHINE,
    id,
  }),
  toggleSelectedOnAllMachines: (toggledState) => ({
    type: types.gymUtilization.TOGGLE_SELECT_ALL_MACHINES,
    toggledState,
  }),
  setDate: (from, to) => ({
    type: types.gymUtilization.SET_DATE,
    from,
    to,
  }),
  setSort: (sortOn) => ({
    type: types.gymUtilization.SET_SORT,
    sortOn,
  }),

  getTaps: (id, timeStamp) => ({
    siteId: id,
    lastRequestDatePuck: timeStamp,
    type: types.gymUtilization.GET_TAPS,
  }),
  calculatingTaps: () => ({
    type: types.gymUtilization.CALCULATING_TAPS,
  }),
  getTapsResponse: (data, timeStamp) => ({
    type: types.gymUtilization.GET_TAPS_RESPONSE,
    lastRequestDatePuck: timeStamp,
    data,
  }),
  getTapsFail: (error) => ({
    type: types.gymUtilization.GET_TAPS_FAIL,
    error,
  }),
  selectPuck: (id) => ({
    type: types.gymUtilization.SELECT_PUCK,
    id,
  }),
  toggleSelectedOnAllPucks: (toggledState) => ({
    type: types.gymUtilization.TOGGLE_SELECT_ALL_PUCKS,
    toggledState,
  }),
}

export const gymContentStatistics = {
  // ==== ROUTINES ==== //
  getRoutines: (id, timeStamp) => ({
    siteId: id,
    lastRequestDateRoutines: timeStamp,
    type: types.gymContentStatistics.GET_ROUTINES,
  }),
  getRoutinesResponse: (data, timeStamp) => ({
    type: types.gymContentStatistics.GET_ROUTINES_RESPONSE,
    lastRequestDateRoutines: timeStamp,
    data,
  }),
  getRoutinesFail: (error) => ({
    type: types.gymContentStatistics.GET_ROUTINES_FAIL,
    error,
  }),
  getRoutinesDistribution: (id, timeStamp) => ({
    siteId: id,
    lastRequestDateRoutinesDistribution: timeStamp,
    type: types.gymContentStatistics.GET_ROUTINES_DISTRIBUTION,
  }),
  getRoutinesDistributionResponse: (data, timeStamp) => ({
    type: types.gymContentStatistics.GET_ROUTINES_DISTRIBUTION_RESPONSE,
    lastRequestDateRoutinesDistribution: timeStamp,
    data,
  }),
  getRoutinesDistributionFail: (error) => ({
    type: types.gymContentStatistics.GET_ROUTINES_DISTRIBUTION_FAIL,
    error,
  }),
  selectRoutine: (id) => ({
    type: types.gymContentStatistics.SELECT_ROUTINE,
    id,
  }),
  toggleSelectedOnAllRoutines: (toggledState) => ({
    type: types.gymContentStatistics.TOGGLE_SELECT_ALL_ROUTINES,
    toggledState,
  }),

  // ==== EXERCISES ==== //
  getExercises: (id, timeStamp) => ({
    siteId: id,
    lastRequestDateExercises: timeStamp,
    type: types.gymContentStatistics.GET_EXERCISES,
  }),
  getExercisesResponse: (data, timeStamp) => ({
    type: types.gymContentStatistics.GET_EXERCISES_RESPONSE,
    lastRequestDateExercises: timeStamp,
    data,
  }),
  getExercisesFail: (error) => ({
    type: types.gymContentStatistics.GET_EXERCISES_FAIL,
    error,
  }),
  selectExercise: (id) => ({
    type: types.gymContentStatistics.SELECT_EXERCISE,
    id,
  }),
  toggleSelectedOnAllExercises: (toggledState) => ({
    type: types.gymContentStatistics.TOGGLE_SELECT_ALL_EXERCISES,
    toggledState,
  }),
  // ==== OTHER ==== //
  setDate: (from, to) => ({
    type: types.gymContentStatistics.SET_DATE,
    from,
    to,
  }),
  setSort: (sortOn) => ({
    type: types.gymContentStatistics.SET_SORT,
    sortOn,
  }),
}

export const gymMemberStatistics = {
  setDate: (from, to) => ({
    type: types.gymMemberStatistics.SET_DATE,
    from,
    to,
  }),
  setSort: (sortOn) => ({
    type: types.gymMemberStatistics.SET_SORT,
    sortOn,
  }),
  setPickerSort: (sortPickerOn) => ({
    type: types.gymMemberStatistics.SET_PICKER_SORT,
    sortPickerOn,
  }),
  setLangFormat: (lang) => ({
    type: types.gymMemberStatistics.SET_LANG_FORMAT,
    lang,
  }),
  getRetention: (id, timeStamp) => ({
    siteId: id,
    lastRequestDate: timeStamp,
    type: types.gymMemberStatistics.GET_RETENTION,
  }),
  responseRetention: (data, id, timeStamp) => ({
    type: types.gymMemberStatistics.RESPONSE_RETENTION,
    data,
    lastRequestDate: timeStamp,
    siteId: id,
  }),
  failureRetention: (error) => ({
    type: types.gymMemberStatistics.FAILURE_RETENTION,
    error,
  }),
  getAllRetention: (id, timeStamp) => ({
    siteId: id,
    lastRequestDate: timeStamp,
    type: types.gymMemberStatistics.GET_ALL_RETENTION,
  }),
  responseAllRetention: (data, id, timeStamp) => ({
    type: types.gymMemberStatistics.RESPONSE_ALL_RETENTION,
    data,
    lastRequestDate: timeStamp,
    siteId: id,
  }),
  failureAllRetention: (error) => ({
    type: types.gymMemberStatistics.FAILURE_ALL_RETENTION,
    error,
  }),
  getMemberIncrement: (id, timeStamp) => ({
    siteId: id,
    lastRequestDate: timeStamp,
    type: types.gymMemberStatistics.GET_INCREMENT,
  }),
  responseMemberIncrement: (data, id, timeStamp) => ({
    type: types.gymMemberStatistics.RESPONSE_INCREMENT,
    data,
    lastRequestDate: timeStamp,
    siteId: id,
  }),
  failureMemberIncrement: (error) => ({
    type: types.gymMemberStatistics.FAILURE_INCREMENT,
    error,
  }),
}

export const gymHourActivity = {
  setDate: (from, to) => ({
    type: types.gymHourActivity.SET_DATE,
    from,
    to,
  }),
  get: (id) => ({
    siteId: id,
    type: types.gymHourActivity.GET,
  }),
  calculating: () => ({
    type: types.gymHourActivity.CALCULATING,
  }),
  response: (data, id) => ({
    type: types.gymHourActivity.RESPONSE,
    data,
    siteId: id,
  }),
  failure: (error) => ({
    type: types.gymHourActivity.FAILURE,
    error,
  }),
}

export const partners = {
  get: function () {
    return { type: types.partners.GET }
  },
  response: function (response) {
    return { type: types.partners.RESPONSE, partners: response.data }
  },
  getFailed: function (error) {
    return { type: types.partners.GET_FAIL, error }
  },
}

export const partner = {
  get: function (partnerID, what) {
    return { type: types.partner.GET, partnerID, what }
  },
  response: function (partnerID, partner, what) {
    return { type: types.partner.GET_RESPONSE, partnerID, partner, what }
  },
  getFail: function (partnerID, error, what) {
    return { type: types.partner.GET_FAIL, partnerID, what, error }
  },
  save: function (partner, what) {
    return { type: types.partner.SAVE, partnerID: partner._id, what }
  },
  saveResponse: function (partner, what) {
    return {
      type: types.partner.SAVE_RESPONSE,
      partnerID: partner._id,
      partner,
      what,
    }
  },
  saveFail: function (partner, error, what) {
    return {
      type: types.partner.SAVE_FAIL,
      partnerID: partner._id,
      what,
      error,
    }
  },
  pictureFailed: (error) => ({
    type: types.partner.LOGO_ERROR,
    error,
  }),
  delete: function (partner, what) {
    return { type: types.partner.DELETE, partnerID: partner._id, what }
  },
  deleteResponse: function (partner, what) {
    return {
      type: types.partner.DELETE_RESPONSE,
      partnerID: partner._id,
      partner,
      what,
    }
  },
  deleteFail: function (partner, error, what) {
    return {
      type: types.partner.DELETE_FAIL,
      partnerID: partner._id,
      what,
      error,
    }
  },
  getUsers: function (partnerID, what) {
    return { type: types.partner.GET_USERS, partnerID, what }
  },
  getUsersResponse: function (partnerID, users, what) {
    return { type: types.partner.GET_USERS_RESPONSE, partnerID, users, what }
  },
  getUsersFail: function (partnerID, error, what) {
    return { type: types.partner.GET_USERS_FAIL, partnerID, what, error }
  },
  addUser: function (partnerID, user) {
    return { type: types.partner.ADD_USER, partnerID, user }
  },
  addUserResponse: function (partnerID, user) {
    return { type: types.partner.ADD_USER_RESPONSE, partnerID, user }
  },
  addUserFail: function (partnerID, error) {
    return { type: types.partner.ADD_USER_FAIL, partnerID, error }
  },
  removeUser: function (partnerID, user) {
    return { type: types.partner.REMOVE_USER, partnerID, user }
  },
  removeUserResponse: function (partnerID, user) {
    return { type: types.partner.REMOVE_USER_RESPONSE, partnerID, user }
  },
  removeUserFail: function (partnerID, user) {
    return { type: types.partner.REMOVE_USER_FAIL, partnerID, user }
  },
  clear: function () {
    return { type: types.partner.CLEAR }
  },
  getDas: function () {
    return { type: types.partner.GET_DAS }
  },
  dasResponse: function (das) {
    return { type: types.partner.DAS_RESPONSE, das }
  },
  dasFail: function (error) {
    return { type: types.partner.DAS_FAIL, error }
  },
  clearDas: function () {
    return { type: types.partner.CLEAR_DAS }
  },
}

export const partnerGym = {
  get: (partnerID, gymId, what) => ({
    type: types.partnerGym.GET,
    partnerID,
    gymId,
    what,
  }),
  response: (partnerID, gymId, gym, what) => ({
    type: types.partnerGym.GET_RESPONSE,
    partnerID,
    gymId,
    gym,
    what,
  }),
  getFail: (partnerID, gymId, error, what) => ({
    type: types.partnerGym.GET_FAIL,
    partnerID,
    gymId,
    what,
    error,
  }),
  create: (partnerID, gymName, what) => ({
    type: types.partnerGym.CREATE,
    partnerID,
    gymName,
    what,
  }),
  createResponse: (partnerID, gymName, responseData, redirectTo, what) => ({
    type: types.partnerGym.CREATE_RESPONSE,
    partnerID,
    gymName,
    responseData,
    redirectTo,
    what,
  }),
  createFail: (partnerID, gymName, error, what) => ({
    type: types.partnerGym.CREATE_FAIL,
    partnerID,
    gymName,
    error,
    what,
  }),
  save: (partnerID, gymId, what) => ({
    type: types.partnerGym.GET,
    partnerID,
    gymId,
    what,
  }),
  saveResponse: (gymId, partnerID, gym, what) => ({
    type: types.partnerGym.SAVE_RESPONSE,
    gymId,
    partnerID,
    gym,
    what,
  }),
  saveFailed: (id, error) => ({
    type: types.partnerGym.SAVE_FAIL,
    id,
    error,
  }),
  clear: () => ({
    type: types.partnerGym.CLEAR,
  }),
}

export const clients = {
  get: function () {
    return { type: types.clients.GET }
  },
  response: function (responseData) {
    return { type: types.clients.RESPONSE, clients: responseData }
  },
  getFailed: function (error) {
    return { type: types.clients.GET_FAIL, error }
  },
  cancelSet: function (email) {
    return { type: types.clients.CANCEL_SET, email }
  },
  cancelClear: function () {
    return { type: types.clients.CANCEL_CLEAR }
  },

  getMoreClients: function () {
    return { type: types.clients.GET_MORE_CLIENTS }
  },
  getMoreClientsResponse: function (clients) {
    return {
      type: types.clients.GET_MORE_CLIENTS_RESPONSE,
      clients,
    }
  },
  getMoreClientsFailed: function (clients, error) {
    return { type: types.clients.GET_MORE_CLIENTS_FAILED, clients, error }
  },

  searchForClients: function () {
    return { type: types.clients.SEARCH_FOR_CLIENTS }
  },
  searchForClientsResponse: function (clients) {
    return {
      type: types.clients.SEARCH_FOR_CLIENTS_RESPONSE,
      clients,
    }
  },
  searchForClientsFailed: function (clients, error) {
    return {
      type: types.clients.SEARCH_FOR_CLIENTS_FAILED,
      clients,
      error,
    }
  },
  searchForClientsClear: function () {
    return { type: types.clients.SEARCH_FOR_CLIENTS_CLEAR }
  },

  getUnreadMessagesResponse: function (unreadMessages) {
    return {
      type: types.clients.GET_ALL_UNREAD_MESSAGES_RESPONSE,
      unreadMessages,
    }
  },

  getClientCount: function () {
    return { type: types.clients.GET_CLIENT_COUNT }
  },
  getClientCountResponse: function (clients) {
    return {
      type: types.clients.GET_CLIENT_COUNT_RESPONSE,
      clients,
    }
  },
  getClientCountFailed: function (members, error) {
    return {
      type: types.clients.GET_CLIENT_COUNT_FAILED,
      clients,
      error,
    }
  },
  addClient: function () {
    return {
      type: types.clients.ADD_CLIENT,
    }
  },
  addClientResponse: function (client) {
    return {
      type: types.clients.ADD_CLIENT_RESPONSE,
      client,
    }
  },
  addClientFailed: function (errorType, error) {
    return {
      type: types.clients.ADD_CLIENT_FAILED,
      errorType,
      error,
    }
  },
  addClientClear: function () {
    return {
      type: types.clients.ADD_CLIENT_CLEAR,
    }
  },
}

export const client = {
  get: function (clientId) {
    return { type: types.client.GET, id: clientId, what: { type: 'fetching' } }
  },
  response: function (data, clientId) {
    return {
      type: types.client.RESPONSE,
      client: data.client,
      shares: data.shares,
      id: clientId,
      what: { type: 'fetching' },
    }
  },
  getFailed: function (error, clientId) {
    return {
      type: types.client.GET_FAIL,
      error,
      id: clientId,
      what: { type: 'fetching' },
    }
  },
  update: function (clientId, what) {
    return { type: types.client.UPDATE, id: clientId, what }
  },
  updateResponse: function (data, clientId) {
    return {
      type: types.client.UPDATE_RESPONSE,
      id: clientId,
      client: data.client,
      shares: data.shares,
    }
  },
  updateFailed: function (clientId, error) {
    return { type: types.client.UPDATE_FAIL, error, id: clientId }
  },
  share: function (clientId, programId) {
    return { type: types.client.SHARE, id: clientId, programId }
  },
  shareResponse: function (clientId, programId) {
    return { type: types.client.SHARE_RESPONSE, id: clientId, programId }
  },
  shareFailed: function (clientId, error, programId) {
    return {
      type: types.client.SHARE_FAIL,
      error,
      id: clientId,
      programId,
    }
  },
  remove: function (clientId) {
    return { type: types.client.REMOVE, id: clientId }
  },
  removeResponse: function (clientId) {
    return { type: types.client.REMOVE_RESPONSE, id: clientId }
  },
  removeFailed: function (clientId) {
    return { type: types.client.REMOVE_FAIL, id: clientId }
  },

  // ======= WORKOUTS ==========//
  getShares: function (clientId) {
    return {
      type: types.client.GET_SHARES,
      id: clientId,
      what: { type: 'fetching' },
    }
  },
  sharesResponse: function (data, clientId) {
    return {
      type: types.client.SHARES_RESPONSE,
      shares: data.shares,
      id: clientId,
      what: { type: 'fetching' },
    }
  },
  sharesFailed: function (error, clientId) {
    return {
      type: types.client.SHARES_FAILED,
      error,
      id: clientId,
      what: { type: 'fetching' },
    }
  },
  saveShare: function (share) {
    return { type: types.client.SAVE_SHARE, share }
  },
  saveShareResponse: function (share) {
    return { type: types.client.SAVE_SHARE_RESPONSE, share }
  },
  saveShareFailed: function (error) {
    return { type: types.client.SAVE_SHARE_FAILED, error }
  },
  removeShare: function () {
    return { type: types.client.REMOVE_SHARE }
  },
  removeShareResponse: function (routineId) {
    return { type: types.client.REMOVE_SHARE_RESPONSE, routineId }
  },
  removeShareFailed: function (error) {
    return { type: types.client.REMOVE_SHARE_FAIL, error }
  },
  addShare: function (share, clear) {
    return { type: types.client.ADD_SHARE, share, clear }
  },
  progressClear: function () {
    return { type: types.client.PROGRESS_CLEAR }
  },

  // ======= NOTES ==========//
  getClientNotes: function () {
    return { type: types.client.GET_NOTES }
  },
  getClientNotesResponse: function (notes) {
    return {
      type: types.client.GET_NOTES_RESPONSE,
      notes,
    }
  },
  getClientNotesFailed: function (notes, error) {
    return { type: types.client.GET_NOTES_FAILED, notes, error }
  },

  saveClientNote: function (note) {
    return { type: types.client.SAVE_NOTE, note }
  },
  saveClientNoteResponse: function (note) {
    return { type: types.client.SAVE_NOTE_RESPONSE, note }
  },
  saveClientNoteFailed: function (note, error) {
    return { type: types.client.SAVE_NOTE_FAILED, note, error }
  },

  removeNote: function (note) {
    return { type: types.client.REMOVE_NOTE, note }
  },
  removeNoteResponse: function (note) {
    return {
      type: types.client.REMOVE_NOTE_RESPONSE,
      note,
    }
  },
  removeNoteFailed: function (note, error) {
    return {
      type: types.client.REMOVE_NOTE_FAILED,
      note,
      error,
    }
  },

  createClientNote: function (note) {
    return { type: types.client.CREATE_NOTE, note }
  },
  createClientNoteResponse: function (note) {
    return { type: types.client.CREATE_NOTE_RESPONSE, note }
  },
  createClientNoteFailed: function (note, error) {
    return { type: types.client.CREATE_NOTE_FAILED, note, error }
  },

  setReadAll: () => ({
    type: types.client.SET_READ_ALL,
  }),
}

export const myGymProgramCollections = {
  get: () => ({ type: types.myGymProgramCollections.GET }),
  response: (programCollections) => {
    return {
      type: types.myGymProgramCollections.RESPONSE,
      programCollections,
    }
  },
  getFailed: (error) => ({
    type: types.myGymProgramCollections.GET_FAIL,
    error,
  }),
  updateVisibility: function (updatedList) {
    return {
      type: types.myGymProgramCollections.UPDATE_VISIBILITY,
      updatedList: updatedList,
    }
  },
  filterOutCollection: function (collection) {
    return {
      type: types.myGymProgramCollections.FILTER_OUT_COLLECTION,
      collection,
    }
  },

  copy: function () {
    return { type: types.myGymProgramCollections.COPY }
  },
  copyResponse: function () {
    return { type: types.myGymProgramCollections.COPY_RESPONSE }
  },
  copyFailed: function (error) {
    return { type: types.myGymProgramCollections.COPY_FAILED, error }
  },
}

export const myGymProgramCollection = {
  get: (id, what) => ({ type: types.myGymProgramCollection.GET, id, what }),
  save: (id, programCollection, what) => ({
    type: types.myGymProgramCollection.SAVE,
    id,
    programCollection,
    what,
  }),
  delete: (id, what) => ({
    type: types.myGymProgramCollection.DELETE,
    id,
    what,
  }),
  duplicate: (id, programCollection, what) => ({
    type: types.myGymProgramCollection.DUPLICATE,
    id,
    programCollection,
    what,
  }),
  response: (id, programCollections) => ({
    type: types.myGymProgramCollection.RESPONSE,
    id,
    programCollections,
  }),
  saveResponse: (id, programCollections) => ({
    type: types.myGymProgramCollection.SAVE_RESPONSE,
    id,
    programCollections,
  }),
  deleteResponse: (id) => ({
    type: types.myGymProgramCollection.DELETE_RESPONSE,
    id,
  }),
  duplicateResponse: (id, programCollections) => ({
    type: types.myGymProgramCollection.DUPLICATE_RESPONSE,
    id,
    programCollections,
  }),
  getFailed: (id, error) => ({
    type: types.myGymProgramCollection.GET_FAIL,
    id,
    error,
  }),
  saveFailed: (id, error) => ({
    type: types.myGymProgramCollection.SAVE_FAIL,
    id,
    error,
  }),
  deleteFailed: (id, error) => ({
    type: types.myGymProgramCollection.DELETE_FAIL,
    id,
    error,
  }),
  duplicateFailed: (id, error) => ({
    type: types.myGymProgramCollection.DUPLICATE_FAIL,
    id,
    error,
  }),
  dismissDuplicateDialog: () => ({
    type: types.myGymProgramCollection.DISMISS_DUPLICATE_DIALOG,
  }),
  setMode: (mode) => ({ type: types.myGymProgramCollection.SET_MODE, mode }),

  work: function (id, collection, site, percentage, what) {
    return {
      type: types.myGymProgramCollection.WORK,
      id,
      collection,
      site,
      percentage,
      what,
    }
  },
  workResponse: function (id, site, percentage, result, what) {
    return {
      type: types.myGymProgramCollection.WORK_RESPONSE,
      id,
      site,
      percentage,
      result,
      what,
    }
  },
  clearWork: function () {
    return { type: types.myGymProgramCollection.CLEAR_WORK }
  },
}

export const myGymCategories = {
  get: () => ({ type: types.myGymCategories.GET }),
  response: (categories) => ({
    type: types.myGymCategories.RESPONSE,
    categories,
  }),
  getFailed: (error) => ({
    type: types.myGymCategories.GET_FAIL,
    error,
  }),
  save: () => ({ type: types.myGymCategories.SAVE }),
  saveFailed: (error) => ({
    type: types.myGymCategories.SAVE_FAIL,
    error,
  }),

  updateProgram: (categories, program, unit) => ({
    type: types.myGymCategories.UPDATE_WORKOUT,
    categories,
    program,
    unit,
  }),
  removeItem: (content, _id) => ({
    type: types.myGymCategories.REMOVE_ITEM,
    content,
    _id,
  }),
}

export const profileSettings = {
  unitSave: () => ({
    type: types.profileSettings.PROFILE_UNIT_SETTINGS_SAVE,
  }),
  unitResponse: (response) => ({
    type: types.profileSettings.PROFILE_UNIT_SETTINGS_RESPONE,
    response,
  }),
  unitFailed: (error) => ({
    type: types.profileSettings.PROFILE_UNIT_SETTINGS_ERROR,
    error,
  }),
  languageSave: () => ({
    type: types.profileSettings.PROFILE_LANGUAGE_SETTINGS_SAVE,
  }),
  languageResponse: (response) => ({
    type: types.profileSettings.PROFILE_LANGUAGE_SETTINGS_RESPONE,
    response,
  }),
  languageFailed: (error) => ({
    type: types.profileSettings.PROFILE_LANGUAGE_SETTINGS_ERROR,
    error,
  }),
  pictureSave: () => ({
    type: types.profileSettings.PROFILE_PICTURE_SAVE,
  }),
  pictureResponse: (response) => ({
    type: types.profileSettings.PROFILE_PICTURE_RESPONE,
    response,
  }),
  pictureFailed: (error) => ({
    type: types.profileSettings.PROFILE_PICTURE_ERROR,
    error,
  }),
  termsSave: () => ({
    type: types.profileSettings.PROFILE_TERMS_SAVE,
  }),
  termsResponse: (response) => ({
    type: types.profileSettings.PROFILE_TERMS_RESPONE,
    response,
  }),
  termsFailed: (error) => ({
    type: types.profileSettings.PROFILE_TERMS_ERROR,
    error,
  }),
  ptEmailNotificationSave: () => ({
    type: types.profileSettings.PROFILE_PT_NOTIFICATION_SAVE,
  }),
  ptEmailNotificationResponse: (response) => ({
    type: types.profileSettings.PROFILE_PT_NOTIFICATION_RESPONE,
    response,
  }),
  ptEmailNotificationFailed: (error) => ({
    type: types.profileSettings.PROFILE_PT_NOTIFICATION_ERROR,
    error,
  }),
  sendFeedback: () => ({
    type: types.profileSettings.PROFILE_SEND_FEEDBACK,
  }),
  sendFeedbackResponse: () => ({
    type: types.profileSettings.PROFILE_SEND_FEEDBACK_RESPONE,
  }),
  sendFeedbackFailed: (error) => ({
    type: types.profileSettings.PROFILE_SEND_FEEDBACK_ERROR,
    error,
  }),
  getCopyToSites: () => ({
    type: types.profileSettings.PROFILE_GET_COPY_TO_SITES,
  }),
  getCopyToSitesResponse: () => ({
    type: types.profileSettings.PROFILE_GET_COPY_TO_SITES_RESPONE,
  }),
  getCopyToSitesFailed: () => ({
    type: types.profileSettings.PROFILE_GET_COPY_TO_SITES_FAILURE,
  }),
}

export const manageGymPermissions = {
  get: function () {
    return { type: types.manageGymPermissions.GET }
  },
  getResponse: function (users) {
    return {
      type: types.manageGymPermissions.GET_RESPONSE,
      users,
    }
  },
  getFailed: function (error) {
    return { type: types.manageGymPermissions.GET_FAIL, error }
  },

  remove: function (email) {
    return { type: types.manageGymPermissions.REMOVE, email }
  },
  removeResponse: function (email) {
    return {
      type: types.manageGymPermissions.REMOVE_RESPONSE,
      email,
    }
  },
  removeFailed: function (error) {
    return { type: types.manageGymPermissions.REMOVE_FAIL, error }
  },

  add: function () {
    return { type: types.manageGymPermissions.ADD }
  },
  addResponse: function (user, permission) {
    return {
      type: types.manageGymPermissions.ADD_RESPONSE,
      user,
      permission,
    }
  },
  addFailed: function (error) {
    return { type: types.manageGymPermissions.ADD_FAIL, error }
  },

  update: function (email) {
    return { type: types.manageGymPermissions.UPDATE, email }
  },
  updateResponse: function (user) {
    return {
      type: types.manageGymPermissions.UPDATE_RESPONSE,
      user,
    }
  },
  updateFailed: function (error) {
    return { type: types.manageGymPermissions.UPDATE_FAIL, error }
  },

  clear: function () {
    return { type: types.manageGymPermissions.CLEAR }
  },
}

export const gymStaff = {
  get: function () {
    return { type: types.myGymStaff.GET }
  },
  getResponse: function (user) {
    return {
      type: types.myGymStaff.GET_RESPONSE,
      user,
    }
  },
  getFailed: function (error) {
    return { type: types.myGymStaff.GET_FAIL, error }
  },
  update: function (role) {
    return { type: types.myGymStaff.UPDATE, role }
  },

  remove: function () {
    return { type: types.myGymStaff.REMOVE }
  },
  removeResponse: function () {
    return {
      type: types.myGymStaff.REMOVE_RESPONSE,
    }
  },
  removeFailed: function (error) {
    return { type: types.myGymStaff.REMOVE_FAIL, error }
  },

  // ===== CLIENTS ==== //
  getClients: function () {
    return { type: types.myGymStaff.GET_CLIENTS }
  },
  getClientsResponse: function (clients) {
    return {
      type: types.myGymStaff.GET_CLIENTS_RESPONSE,
      clients,
    }
  },
  getClientsFailed: function (error) {
    return { type: types.myGymStaff.GET_CLIENTS_FAIL, error }
  },
  searchForClients: function () {
    return { type: types.myGymStaff.SEARCH_FOR_CLIENTS }
  },
  searchForClientsResponse: function (clients) {
    return {
      type: types.myGymStaff.SEARCH_FOR_CLIENTS_RESPONSE,
      clients,
    }
  },
  searchForClientsFailed: function (clients, error) {
    return {
      type: types.myGymStaff.SEARCH_FOR_CLIENTS_FAILED,
      clients,
      error,
    }
  },
  getClientsStatistics: function (timeStamp) {
    return { type: types.myGymStaff.GET_CLIENTS_STATISTICS, timeStamp }
  },
  getClientsStatisticsResponse: function (clients, timeStamp) {
    return {
      type: types.myGymStaff.GET_CLIENTS_STATISTICS_RESPONSE,
      clients,
      timeStamp,
    }
  },
  getClientsStatisticsFailed: function (error) {
    return { type: types.myGymStaff.GET_CLIENTS_STATISTICS_FAIL, error }
  },

  // ===== MEMBERS ==== //
  getMembers: function () {
    return { type: types.myGymStaff.GET_MEMBERS }
  },
  getMembersResponse: function (members) {
    return {
      type: types.myGymStaff.GET_MEMBERS_RESPONSE,
      members,
    }
  },
  getMembersFailed: function (error) {
    return { type: types.myGymStaff.GET_MEMBERS_FAIL, error }
  },
  searchForMembers: function () {
    return { type: types.myGymStaff.SEARCH_FOR_MEMBERS }
  },
  searchForMembersResponse: function (members) {
    return {
      type: types.myGymStaff.SEARCH_FOR_MEMBERS_RESPONSE,
      members,
    }
  },
  searchForMembersFailed: function (members, error) {
    return {
      type: types.myGymStaff.SEARCH_FOR_MEMBERS_FAILED,
      members,
      error,
    }
  },
  getMembersStatistics: function (timeStamp) {
    return { type: types.myGymStaff.GET_MEMBERS_STATISTICS, timeStamp }
  },
  getMembersStatisticsResponse: function (members, timeStamp) {
    return {
      type: types.myGymStaff.GET_MEMBERS_STATISTICS_RESPONSE,
      members,
      timeStamp,
    }
  },
  getMembersStatisticsFailed: function (error) {
    return { type: types.myGymStaff.GET_MEMBERS_STATISTICS_FAIL, error }
  },

  clear: function () {
    return { type: types.myGymStaff.CLEAR }
  },
  searchForClear: function () {
    return { type: types.myGymStaff.SEARCH_FOR_CLEAR }
  },
}

export const advagymStatistics = {
  get: () => ({ type: types.advagymStatistics.GET }),
  response: (data) => ({
    type: types.advagymStatistics.RESPONSE,
    data,
  }),
  failure: (error) => ({
    type: types.advagymStatistics.FAILURE,
    error,
  }),

  getNew: () => ({ type: types.advagymStatistics.GET_NEW }),
  responseNew: (data) => ({
    type: types.advagymStatistics.RESPONSE_NEW,
    data,
  }),
  failureNew: (error) => ({
    type: types.advagymStatistics.FAILURE_NEW,
    error,
  }),

  setDate: (from, to) => ({
    type: types.advagymStatistics.SET_DATE,
    from,
    to,
  }),
}

export const translationTools = {
  addExercise: (exercise) => ({
    type: types.translationTools.ADD_EXERCISE,
    exercise,
  }),
  removeExercise: (exercise) => ({
    type: types.translationTools.REMOVE_EXERCISE,
    exercise,
  }),
  clearExercises: () => ({
    type: types.translationTools.CLEAR_EXERCISES,
  }),
}

export const gallery = {
  get: () => ({
    type: types.gallery.GET,
  }),
  response: (gallery) => ({
    type: types.gallery.RESPONSE,
    gallery,
  }),
  fail: () => ({
    type: types.gallery.FAIL,
  }),
}

export const tabs = {
  update: (tab) => ({
    type: types.tabs.UPDATE,
    tab,
  }),
}

export const actionFooter = {
  set: ({ left, right, extra, show }) => ({
    type: types.actionFooter.SET,
    leftText: left ? (left.text ? left.text : null) : null,

    rightText: right ? (right.text ? right.text : null) : null,
    rightIcon: right ? (right.icon ? right.icon : null) : null,
    rightButtonColor: right ? (right.color ? right.color : null) : null,
    rightDisabled: right ? (right.disabled ? right.disabled : null) : null,

    extraRightText: extra ? (extra.text ? extra.text : null) : null,
    extraRightIcon: extra ? (extra.icon ? extra.icon : null) : null,
    extraRightButtonColor: extra ? (extra.color ? extra.color : null) : null,
    extraRightDisabled: extra ? (extra.disabled ? extra.disabled : null) : null,

    show: show ? show : true,
  }),
  clear: () => ({
    type: types.actionFooter.CLEAR,
  }),
  unclear: () => ({
    type: types.actionFooter.UNCLEAR,
  }),
  callLeft: () => ({
    type: types.actionFooter.CALL_LEFT,
  }),
  reactLeft: () => ({
    type: types.actionFooter.REACT_LEFT,
  }),
  callRight: () => ({
    type: types.actionFooter.CALL_RIGHT,
  }),
  reactRight: () => ({
    type: types.actionFooter.REACT_RIGHT,
  }),
  disableRight: () => ({
    type: types.actionFooter.DISABLE_RIGHT,
  }),
  enableRight: () => ({
    type: types.actionFooter.ENABLE_RIGHT,
  }),
  callExtraRight: () => ({
    type: types.actionFooter.CALL_EXTRA_RIGHT,
  }),
  reactExtraRight: () => ({
    type: types.actionFooter.REACT_EXTRA_RIGHT,
  }),
  disableExtraRight: () => ({
    type: types.actionFooter.DISABLE_EXTRA_RIGHT,
  }),
  enableExtraRight: () => ({
    type: types.actionFooter.ENABLE_EXTRA_RIGHT,
  }),
}

export const userDashboardStatistics = {
  // ======== GENERAL ========= //
  getGeneral: () => ({
    type: types.userDashboardStatistics.GET_GENERAL,
  }),
  getGeneralResponse: (data) => ({
    type: types.userDashboardStatistics.GET_GENERAL_RESPONSE,
    data,
  }),
  getGeneralFailed: () => ({
    type: types.userDashboardStatistics.GET_GENERAL_FAILED,
  }),
  // ======== WORKOUTS ========= //
  getPerformedProgramsResponse: (id) => ({
    type: types.userDashboardStatistics.GET_PERFORMED_WORKOUTS_RESPONSE,
    id,
  }),
  getPrograms: () => ({
    type: types.userDashboardStatistics.GET_WORKOUTS,
  }),
  getProgramsResponse: (result) => ({
    type: types.userDashboardStatistics.GET_WORKOUTS_RESPONSE,
    result,
  }),
  getProgramsFailed: () => ({
    type: types.userDashboardStatistics.GET_WORKOUTS_FAILED,
  }),
  getProgramsSelection: (programs) => ({
    type: types.userDashboardStatistics.GET_WORKOUTS_SELECTION,
    programs,
  }),
  getProgramsSelectionResponse: (result, programs) => ({
    type: types.userDashboardStatistics.GET_WORKOUTS_SELECTION_RESPONSE,
    result,
    programs,
  }),
  getProgramsSelectionFailed: (program) => ({
    type: types.userDashboardStatistics.GET_WORKOUTS_SELECTION_FAILED,
    program,
  }),
  // ======== EXERCISES ========= //
  getPerformedExercises: () => ({
    type: types.userDashboardStatistics.GET_PERFORMED_EXERCISES,
  }),
  getPerformedExercisesResponse: (id, result) => ({
    type: types.userDashboardStatistics.GET_PERFORMED_EXERCISES_RESPONSE,
    id,
    result,
  }),
  getPerformedExercisesFailed: () => ({
    type: types.userDashboardStatistics.GET_PERFORMED_EXERCISES_FAILED,
  }),
  getExercises: () => ({
    type: types.userDashboardStatistics.GET_EXERCISES,
  }),
  getExercisesResponse: (result) => ({
    type: types.userDashboardStatistics.GET_EXERCISES_RESPONSE,
    result,
  }),
  getExercisesFailed: () => ({
    type: types.userDashboardStatistics.GET_EXERCISES_FAILED,
  }),
  getExercisesSelection: (exercises) => ({
    type: types.userDashboardStatistics.GET_EXERCISES_SELECTION,
    exercises,
  }),
  getExercisesSelectionResponse: (result, exercises) => ({
    type: types.userDashboardStatistics.GET_EXERCISES_SELECTION_RESPONSE,
    result,
    exercises,
  }),
  getExercisesSelectionFailed: (exercise) => ({
    type: types.userDashboardStatistics.GET_EXERCISES_SELECTION_FAILED,
    exercise,
  }),

  // ======== CARDIO EXERCISES ========= //

  getPerformedCardioExercises: () => ({
    type: types.userDashboardStatistics.GET_PERFORMED_CARDIO_EXERCISES,
  }),
  getPerformedCardioExercisesResponse: (id, result) => ({
    type: types.userDashboardStatistics.GET_PERFORMED_CARDIO_EXERCISES_RESPONSE,
    id,
    result,
  }),
  getPerformedCardioExercisesFailed: () => ({
    type: types.userDashboardStatistics.GET_PERFORMED_CARDIO_EXERCISES_FAILED,
  }),
  getCardioExercises: () => ({
    type: types.userDashboardStatistics.GET_CARDIO_EXERCISES,
  }),
  getCardioExercisesResponse: (result) => ({
    type: types.userDashboardStatistics.GET_CARDIO_EXERCISES_RESPONSE,
    result,
  }),
  getCardioExercisesFailed: () => ({
    type: types.userDashboardStatistics.GET_CARDIO_EXERCISES_FAILED,
  }),
  getCardioExercisesSelection: (exercises) => ({
    type: types.userDashboardStatistics.GET_CARDIO_EXERCISES_SELECTION,
    exercises,
  }),
  getCardioExercisesSelectionResponse: (result, exercises) => ({
    type: types.userDashboardStatistics.GET_CARDIO_EXERCISES_SELECTION_RESPONSE,
    result,
    exercises,
  }),
  getCardioExercisesSelectionFailed: (exercise) => ({
    type: types.userDashboardStatistics.GET_CARDIO_EXERCISES_SELECTION_FAILED,
    exercise,
  }),

  // ======== SORT ON ========= //
  setIntervalSort: (interval, number) => ({
    type: types.userDashboardStatistics.SET_INTEVAL_SORT,
    interval,
    number,
  }),
  setProgramsSort: (end, interval, number) => ({
    type: types.userDashboardStatistics.SET_WORKOUTS_SORT,
    end,
    interval,
    number,
  }),
  setExercisesSort: (end, interval, number) => ({
    type: types.userDashboardStatistics.SET_EXERCISES_SORT,
    end,
    interval,
    number,
  }),
  setCardioExercisesSort: (end, interval, number) => ({
    type: types.userDashboardStatistics.SET_CARDIO_EXERCISES_SORT,
    end,
    interval,
    number,
  }),
}
