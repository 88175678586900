export const STORE_RESET = 'STORE_RESET'

export const mediaQuery = {
  SEND: 'SEND_MEDIA_QUERY',
}

export const join = {
  REQUEST: 'JOIN_REQUEST',
  RESPONSE: 'JOIN_RESPONSE',
  RESET: 'JOIN_RESET',
  SHOW_WELCOME_SCREEN: 'JOIN_SHOW_WELCOME_SCREEN',
  HIDE_WELCOME_SCREEN: 'JOIN_HIDE_WELCOME_SCREEN',
  SHOW_FORM_STATUS: 'JOIN_SHOW_FORM_STATUS',
  HIDE_FORM_STATUS: 'JOIN_HIDE_FORM_STATUS',
  CLEAR_FORM: 'JOIN_CLEAR_FORM',
  ON_INPUT: 'JOIN_ON_INPUT',
}

export const signIn = {
  REQUEST: 'SIGNIN_REQUEST',
  RESPONSE: 'SIGNIN_RESPONSE',
  RESET: 'SIGNIN_RESET',
  SHOW_WELCOME_SCREEN: 'SIGNIN_SHOW_WELCOME_SCREEN',
  HIDE_WELCOME_SCREEN: 'SIGNIN_HIDE_WELCOME_SCREEN',
  SHOW_FORM_STATUS: 'SIGNIN_SHOW_FORM_STATUS',
  HIDE_FORM_STATUS: 'SIGNIN_HIDE_FORM_STATUS',
  CLEAR_FORM: 'SIGNIN_CLEAR_FORM',
  ON_INPUT: 'SIGNIN_ON_INPUT',
}

export const passwordForgotten = {
  REQUEST: 'PASSWORD_FORGOTTEN_REQUEST',
  RESPONSE: 'PASSWORD_FORGOTTEN_RESPONSE',
  RESET: 'PASSWORD_FORGOTTEN_RESET',
  SHOW_WELCOME_SCREEN: 'PASSWORD_FORGOTTEN_SHOW_WELCOME_SCREEN',
  HIDE_WELCOME_SCREEN: 'PASSWORD_FORGOTTEN_HIDE_WELCOME_SCREEN',
  SHOW_FORM_STATUS: 'PASSWORD_FORGOTTEN_SHOW_FORM_STATUS',
  HIDE_FORM_STATUS: 'PASSWORD_FORGOTTEN_HIDE_FORM_STATUS',
  CLEAR_FORM: 'PASSWORD_FORGOTTEN_CLEAR_FORM',
  ON_INPUT: 'PASSWORD_FORGOTTEN_ON_INPUT',
}

export const passwordChange = {
  REQUEST: 'PASSWORD_CHANGE_REQUEST',
  RESPONSE: 'PASSWORD_CHANGE_RESPONSE',
  RESET: 'PASSWORD_CHANGE_RESET',
  SHOW_WELCOME_SCREEN: 'PASSWORD_CHANGE_SHOW_WELCOME_SCREEN',
  HIDE_WELCOME_SCREEN: 'PASSWORD_CHANGE_HIDE_WELCOME_SCREEN',
  SHOW_FORM_STATUS: 'PASSWORD_CHANGE_SHOW_FORM_STATUS',
  HIDE_FORM_STATUS: 'PASSWORD_CHANGE_HIDE_FORM_STATUS',
  CLEAR_FORM: 'PASSWORD_CHANGE_CLEAR_FORM',
  ON_INPUT: 'PASSWORD_CHANGE_ON_INPUT',
}

export const user = {
  POSTED: 'USER_POSTED',
  AUTHORIZED: 'USER_AUTHORIZED',
  UNAUTHORIZED: 'USER_UNAUTHORIZED',
  DEAUTHORIZE: 'USER_DEAUTHORIZE',
  SETTINGS_UNIT_SAVE: 'SETTINGS_UNIT_SAVE',
  SETTINGS_UNIT_RESPONSE: 'SETTINGS_UNIT_RESPONSE',
  SETTINGS_UNIT_FAILURE: 'SETTINGS_UNIT_FAILURE',
  SETTINGS_LANGUAGE_SAVE: 'SETTINGS_LANGUAGE_SAVE',
  SETTINGS_LANGUAGE_RESPONSE: 'SETTINGS_LANGUAGE_RESPONSE',
  SETTINGS_LANGUAGE_FAILURE: 'SETTINGS_LANGUAGE_FAILURE',
  TERMS_SAVE: 'TERMS_SAVE',
  TERMS_RESPONSE: 'TERMS_RESPONSE',
  TERMS_FAILURE: 'TERMS_FAILURE',
  WEB_SETTINGS_SAVE: 'WEB_SETTINGS_SAVE',
  WEB_SETTINGS_RESPONSE: 'WEB_SETTINGS_RESPONSE',
  WEB_SETTINGS_FAILURE: 'WEB_SETTINGS_FAILURE',
  GET_COPY_TO_SITES: 'GET_COPY_TO_SITES',
  GET_COPY_TO_SITES_RESPONSE: 'GET_COPY_TO_SITES_RESPONSE',
  GET_COPY_TO_SITES_FAILURE: 'GET_COPY_TO_SITES_FAILURE',
  SET_CONTENT_LANG: 'SET_CONTENT_LANG',
  RESET_CONTENT_LANG: 'RESET_CONTENT_LANG',
  GET_COPY_STATUS: 'GET_COPY_STATUS',
  GET_UNREAD_SITE_MESSAGES: 'GET_UNREAD_SITE_MESSAGES',
}

export const signOut = {
  REQUESTED: 'SIGNOUT_REQUESTED',
  SUCCESS: 'SIGNOUT_SUCCESS',
  FAILED: 'SIGNOUT_FAILED',
}

export const menu = {
  CLOSE: 'MENU_CLOSE',
  OPEN: 'MENU_OPEN',
  TOGGLE: 'MENU_TOGGLE',
}

export const users = {
  GET: 'USERS_GET',
  RESPONSE: 'USERS_RESPONSE',
  GET_FAIL: 'USERS_GET_FAILED',
}

export const sites = {
  GET: 'SITES_GET',
  RESPONSE: 'SITES_RESPONSE',
  GET_FAIL: 'SITES_GET_FAILED',
  SEARCH: 'SITES_SEARCH',
  SEARCH_RESPONSE: 'SITES_SEARCH_RESPONSE',
  SEARCH_FAILED: 'SITES_SEARCH_FAILED',
  SEARCH_CLEAR: 'SITES_SEARCH_CLEAR',
}

export const exercises = {
  GET: 'EXERCISES_GET',
  RESPONSE: 'EXERCISES_RESPONSE',
  GET_FAIL: 'EXERCISES_GET_FAILED',
}

export const exercise = {
  GET: 'EXERCISE_GET',
  SAVE: 'EXERCISE_SAVE',
  DELETE: 'EXERCISE_DELETE',
  RESPONSE: 'EXERCISE_RESPONSE',
  SAVE_RESPONSE: 'EXERCISE_SAVE_RESPONSE',
  DELETE_RESPONSE: 'EXERCISE_DELETE_RESPONSE',
  GET_FAIL: 'EXERCISE_GET_FAILED',
  SAVE_FAIL: 'EXERCISE_SAVE_FAILED',
  DELETE_FAIL: 'EXERCISE_DELETE_FAILED',
}

export const myGymInfo = {
  GET: 'GYM_INFO_GET',
  GET_RESPONSE: 'GYM_INFO_GET_RESPONSE',
  GET_FAILED: 'GYM_INFO_GET_FAILED',
  SAVE: 'GYM_INFO_SAVE',
  SAVE_RESPONSE: 'GYM_INFO_SAVE_RESPONSE',
  SAVE_FAILED: 'GYM_INFO_SAVE_FAILED',
  CLEAR: 'GYM_INFO_CLEAR',
  GYM_LOGO_ERROR: 'GYM_INFO_GYM_LOGO_ERROR',

  SAVE_BEACON_MESSAGE: 'SAVE_BEACON_MESSAGE',
  SAVE_BEACON_MESSAGE_RESPONSE: 'SAVE_BEACON_MESSAGE_RESPONSE',
  SAVE_BEACON_MESSAGE_FAILED: 'SAVE_BEACON_MESSAGE_FAILED',
}

export const myGymInvitation = {
  GET: 'GYM_INVITATION_GET',
  GET_RESPONSE: 'GYM_INVITATION_GET_RESPONSE',
  GET_FAILED: 'GYM_INVITATION_GET_FAILED',
  GET_SITE: 'GYM_INVITATION_GET_SITE',
  GET_SITE_RESPONSE: 'GYM_INVITATION_GET_SITE_RESPONSE',
  GET_SITE_FAILED: 'GYM_INVITATION_GET_SITE_FAILED',
  GENERATE_NEW: 'GYM_INVITATION_GENERATE_NEW',
  GENERATE_NEW_RESPONSE: 'GYM_INVITATION_GENERATE_NEW_RESPONSE',
  GENERATE_NEW_FAILED: 'GYM_INVITATION_GENERATE_NEW_FAILED',
}

export const gymZones = {
  GET: 'GYM_ZONES_GET',
  RESPONSE: 'GYM_ZONES_RESPONSE',
  GET_FAIL: 'GYM_ZONES_GET_FAILED',
  CREATE: 'GYM_ZONES_CREATE',
  CREATE_RESPONSE: 'GYM_ZONES_CREATE_RESPONSE',
  CREATE_FAILED: 'GYM_ZONES_CREATE_FAILED',
  CLEAR: 'GYM_ZONES_CLEAR',
  FILTER_OUT_ZONE: 'GYM_ZONES_FILTER_OUT_ZONE',
  IMPORT: 'GYM_ZONES_IMPORT',
  IMPORT_RESPONSE: 'GYM_ZONES_IMPORT_RESPONSE',
  IMPORT_FAILED: 'GYM_ZONES_IMPORT_FAILED',
}

export const gymZone = {
  GET: 'GYM_ZONE_GET',
  SAVE: 'GYM_ZONE_SAVE',
  DELETE: 'GYM_ZONE_DELETE',
  RESPONSE: 'GYM_ZONE_RESPONSE',
  SAVE_RESPONSE: 'GYM_ZONE_SAVE_RESPONSE',
  DELETE_RESPONSE: 'GYM_ZONE_DELETE_RESPONSE',
  GET_FAIL: 'GYM_ZONE_GET_FAILED',
  SAVE_FAIL: 'GYM_ZONE_SAVE_FAILED',
  DELETE_FAIL: 'GYM_ZONE_DELETE_FAILED',
  SET_MODE: 'GYM_ZONE_SET_MODE',

  SENDCOPY: 'GYM_ZONE_SENDCOPY',
  SENDCOPY_RESPONSE: 'GYM_ZONE_SENDCOPY_RESPONSE',
  SENDCOPY_COMPLETE: 'GYM_ZONE_SENDCOPY_COMPLETE',

  ADD_EXERCISES: 'GYM_ZONE_ADD_EXERCISES',
  REMOVE_EXERCISE: 'GYM_ZONE_REMOVE_EXERCISE',
  CLEAR_EXERCISES: 'GYM_ZONE_CLEAR_EXERCISES',
}

export const gymMachines = {
  GET: 'GYM_MACHINES_GET',
  RESPONSE: 'GYM_MACHINES_RESPONSE',
  GET_FAIL: 'GYM_MACHINES_GET_FAILED',
  CREATE: 'GYM_MACHINES_CREATE',
  CREATE_RESPONSE: 'GYM_MACHINES_CREATE_RESPONSE',
  CREATE_FAILED: 'GYM_MACHINES_CREATE_FAILED',
  CLEAR: 'GYM_MACHINES_CLEAR',

  WORK: 'MY_GYM_MACHINES_WORK',
  WORK_RESPONSE: 'MY_GYM_MACHINES_WORK_RESPONSE',
  CLEAR_WORK: 'MY_GYM_MACHINES_CLEAR_WORK',

  FILTER_OUT_MACHINE: "MY_GYM_MACHINES_FILTER_OUT_MACHINE",
}


export const gymMachine = {
  GET: 'GYM_MACHINE_GET',
  SAVE: 'GYM_MACHINE_SAVE',
  RESPONSE: 'GYM_MACHINE_RESPONSE',
  SAVE_RESPONSE: 'GYM_MACHINE_SAVE_RESPONSE',
  GET_FAIL: 'GYM_MACHINE_GET_FAILED',
  SAVE_FAIL: 'GYM_MACHINE_SAVE_FAILED',
  SET_MODE: 'GYM_MACHINE_SET_MODE',

  REMOVE: 'GYM_MACHINE_REMOVE',
  REMOVE_RESPONSE: 'GYM_MACHINE_REMOVE_RESPONSE',
  REMOVE_FAILED: 'GYM_MACHINE_REMOVE_FAILED',
}

export const categories = {
  GET: 'CATEGORIES_GET',
  RESPONSE: 'CATEGORIES_RESPONSE',
  GET_FAIL: 'CATEGORIES_GET_FAILED',
}

export const category = {
  GET: 'CATEGORY_GET',
  SAVE: 'CATEGORY_SAVE',
  DELETE: 'CATEGORY_DELETE',
  RESPONSE: 'CATEGORY_RESPONSE',
  SAVE_RESPONSE: 'CATEGORY_SAVE_RESPONSE',
  DELETE_RESPONSE: 'CATEGORY_DELETE_RESPONSE',
  GET_FAIL: 'CATEGORY_GET_FAILED',
  SAVE_FAIL: 'CATEGORY_SAVE_FAILED',
  DELETE_FAIL: 'CATEGORY_DELETE_FAILED',
}

export const collections = {
  GET: 'COLLECTIONS_GET',
  RESPONSE: 'COLLECTIONS_RESPONSE',
  GET_FAIL: 'COLLECTIONS_GET_FAILED',
}

export const collection = {
  GET: 'COLLECTION_GET',
  SAVE: 'COLLECTION_SAVE',
  DELETE: 'COLLECTION_DELETE',
  RESPONSE: 'COLLECTION_RESPONSE',
  SAVE_RESPONSE: 'COLLECTION_SAVE_RESPONSE',
  DELETE_RESPONSE: 'COLLECTION_DELETE_RESPONSE',
  GET_FAIL: 'COLLECTION_GET_FAILED',
  SAVE_FAIL: 'COLLECTION_SAVE_FAILED',
  DELETE_FAIL: 'COLLECTION_DELETE_FAILED',
}

export const programs = {
  GET: 'WORKOUTS_GET',
  RESPONSE: 'WORKOUTS_RESPONSE',
  GET_FAIL: 'WORKOUTS_GET_FAILED',
}

export const program = {
  GET: 'WORKOUT_GET',
  SAVE: 'WORKOUT_SAVE',
  DELETE: 'WORKOUT_DELETE',
  RESPONSE: 'WORKOUT_RESPONSE',
  SAVE_RESPONSE: 'WORKOUT_SAVE_RESPONSE',
  DELETE_RESPONSE: 'WORKOUT_DELETE_RESPONSE',
  GET_FAIL: 'WORKOUT_GET_FAILED',
  SAVE_FAIL: 'WORKOUT_SAVE_FAILED',
  DELETE_FAIL: 'WORKOUT_DELETE_FAILED',
}

export const gym = {
  GET: 'GYM_GET',
  SAVE: 'GYM_SAVE',
  DELETE: 'GYM_DELETE',
  RESPONSE: 'GYM_RESPONSE',
  SAVE_RESPONSE: 'GYM_SAVE_RESPONSE',
  DELETE_RESPONSE: 'GYM_DELETE_RESPONSE',
  GET_FAIL: 'GYM_GET_FAILED',
  SAVE_FAIL: 'GYM_SAVE_FAILED',
  DELETE_FAIL: 'GYM_DELETE_FAILED',
}

export const machine = {
  GET: 'MACHINE_GET',
  SAVE: 'MACHINE_SAVE',
  DELETE: 'MACHINE_DELETE',
  RESPONSE: 'MACHINE_RESPONSE',
  SAVE_RESPONSE: 'MACHINE_SAVE_RESPONSE',
  DELETE_RESPONSE: 'MACHINE_DELETE_RESPONSE',
  GET_FAIL: 'MACHINE_GET_FAILED',
  SAVE_FAIL: 'MACHINE_SAVE_FAILED',
  DELETE_FAIL: 'MACHINE_DELETE_FAILED',
  SET_MODE: 'MACHINE_SET_MODE',
}

export const observer = {
  GET: 'OBSERVER_GET',
  SAVE: 'OBSERVER_SAVE',
  DELETE: 'OBSERVER_DELETE',
  RESPONSE: 'OBSERVER_RESPONSE',
  SAVE_RESPONSE: 'OBSERVER_SAVE_RESPONSE',
  DELETE_RESPONSE: 'OBSERVER_DELETE_RESPONSE',
  GET_FAIL: 'OBSERVER_GET_FAILED',
  SAVE_FAIL: 'OBSERVER_SAVE_FAILED',
  DELETE_FAIL: 'OBSERVER_DELETE_FAILED',
}

export const stackCopy = {
  SET: 'STACK_COPY_SET',
  REMOVE: 'STACK_COPY_REMOVE',
}

export const myWorkout = {
  GET: 'MY_WORKOUT_GET',
  RESPONSE: 'MY_WORKOUT_RESPONSE',
  GET_FAIL: 'MY_WORKOUT_GET_FAILED',
  CLEAR: 'MY_WORKOUT_CLEAR',
}

export const myPrograms = {
  GET: 'MY_WORKOUTS_GET',
  RESPONSE: 'MY_WORKOUTS_RESPONSE',
  GET_FAIL: 'MY_WORKOUTS_GET_FAILED',
  FILTER_OUT_WORKOUT: 'MY_WORKOUTS_FILTER_OUT_WORKOUT',
}

export const myClientMessages = {
  GET_MESSAGES: 'MY_CLIENT_GET_MESSAGES',
  GET_MESSAGES_RESPONSE: 'MY_CLIENT_GET_MESSAGES_RESPONSE',
  GET_MESSAGES_FAIL: 'MY_CLIENT_GET_MESSAGES_FAILED',

  GET_MORE_MESSAGES: 'MY_CLIENT_GET_MORE_MESSAGES',
  GET_MORE_MESSAGES_RESPONSE: 'MY_CLIENT_GET_MESSAGES_MORE_RESPONSE',
  GET_MORE_MESSAGES_FAIL: 'MY_CLIENT_GET_MESSAGES_MORE_FAILED',

  SEND_MESSAGE: 'MY_CLIENT_SEND_MESSAGE',
  SEND_MESSAGE_RESPONSE: 'MY_CLIENT_SEND_MESSAGE_RESPONSE',
  SEND_MESSAGE_FAIL: 'MY_CLIENT_SEND_MESSAGE_FAILED',

  REMOVE_MESSAGE: 'MY_CLIENT_REMOVE_MESSAGE',
  REMOVE_MESSAGE_RESPONSE: 'MY_CLIENT_REMOVE_MESSAGE_RESPONSE',
  REMOVE_MESSAGE_FAIL: 'MY_CLIENT_REMOVE_MESSAGE_FAILED',

  CLEAR: 'MY_CLIENT_CLEAR',

  GET_LIVE_MESSAGES_RESPONSE: "MY_CLIENT_GET_LIVE_MESSAGES_RESPONSE",
}

export const myClientGroupMessages = {
  GET_MESSAGES: 'MY_CLIENT_GROUP_GET_MESSAGES',
  GET_MESSAGES_RESPONSE: 'MY_CLIENT_GROUP_GET_MESSAGES_RESPONSE',
  GET_MESSAGES_FAIL: 'MY_CLIENT_GROUP_GET_MESSAGES_FAILED',

  GET_MORE_MESSAGES: 'MY_CLIENT_GROUP_GET_MORE_MESSAGES',
  GET_MORE_MESSAGES_RESPONSE: 'MY_CLIENT_GROUP_GET_MESSAGES_MORE_RESPONSE',
  GET_MORE_MESSAGES_FAIL: 'MY_CLIENT_GROUP_GET_MESSAGES_MORE_FAILED',

  SEND_MESSAGE: 'MY_CLIENT_GROUP_SEND_MESSAGE',
  SEND_MESSAGE_RESPONSE: 'MY_CLIENT_GROUP_SEND_MESSAGE_RESPONSE',
  SEND_MESSAGE_FAIL: 'MY_CLIENT_GROUP_SEND_MESSAGE_FAILED',

  REMOVE_MESSAGE: 'MY_CLIENT_GROUP_REMOVE_MESSAGE',
  REMOVE_MESSAGE_RESPONSE: 'MY_CLIENT_GROUP_REMOVE_MESSAGE_RESPONSE',
  REMOVE_MESSAGE_FAIL: 'MY_CLIENT_GROUP_REMOVE_MESSAGE_FAILED',
}

export const myClientGroups = {
  CREATE: 'CLIENT_GROUPS_CREATE',
  CREATE_RESPONSE: 'CLIENT_GROUPS_CREATE_RESPONSE',
  CREATE_FAIL: 'CLIENT_GROUPS_CREATE_FAIL',
  LOGO_ERROR: 'CLIENT_GROUPS_LOGO_ERROR',

  GET: 'CLIENT_GROUPS_GET',
  RESPONSE: 'CLIENT_GROUPS_RESPONSE',
  GET_FAIL: 'CLIENT_GROUPS_GET_FAILED',
  CANCEL_SET: 'CLIENT_GROUPS_CANCEL_SET',
  CANCEL_CLEAR: 'CLIENT_GROUPS_CANCEL_CLEAR',

  GET_MORE_CLIENT_GROUPS: 'CLIENT_GROUPS_GET_MORE_CLIENT_GROUPS',
  GET_MORE_CLIENT_GROUPS_RESPONSE: 'CLIENT_GROUPS_GET_MORE_CLIENT_GROUPS_RESPONSE',
  GET_MORE_CLIENT_GROUPS_FAILED: 'CLIENT_GROUPS_GET_MORE_CLIENT_GROUPS_FAILED',

  SEARCH_FOR_CLIENT_GROUPS: 'CLIENT_GROUPS_SEARCH_FOR_CLIENT_GROUPS',
  SEARCH_FOR_CLIENT_GROUPS_RESPONSE: 'CLIENT_GROUPS_SEARCH_FOR_CLIENT_GROUPS_RESPONSE',
  SEARCH_FOR_CLIENT_GROUPS_FAILED: 'CLIENT_GROUPS_SEARCH_FOR_CLIENT_GROUPS_FAILED',
  SEARCH_FOR_CLIENT_GROUPS_CLEAR: 'CLIENT_GROUPS_SEARCH_FOR_CLIENT_GROUPS_CLEAR',

  GET_ALL_UNREAD_MESSAGES_RESPONSE: 'CLIENT_GROUPS_GET_ALL_UNREAD_MESSAGES_RESPONSE',
  GET_CLIENT_GROUPS_COUNT: 'CLIENT_GROUPS_GET_CLIENT_GROUPS_COUNT',
  GET_CLIENT_GROUPS_COUNT_RESPONSE: 'CLIENT_GROUPS_GET_CLIENT_GROUPS_COUNT_RESPONSE',
  GET_CLIENT_GROUPS_COUNT_FAILED: 'CLIENT_GROUPS_GET_CLIENT_GROUPS_COUNT_FAILED',
}

export const myClientGroup = {
  GET: 'MY_CLIENT_GROUP_GET',
  GET_RESPONSE: 'MY_CLIENT_GROUP_GET_RESPONSE',
  GET_FAIL: 'MY_CLIENT_GROUP_GET_FAILED',
  SAVE: 'MY_CLIENT_GROUP_SAVE',
  SAVE_RESPONSE: 'MY_CLIENT_GROUP_SAVE_RESPONSE',
  SAVE_FAIL: 'MY_CLIENT_GROUP_SAVE_FAIL',
  REMOVE_CLIENT: 'MY_CLIENT_GROUP_REMOVE_CLIENT',
  REMOVE_CLIENT_RESPONSE: 'MY_CLIENT_GROUP_REMOVE_CLIENT_RESPONSE',
  REMOVE_CLIENT_FAIL: 'MY_CLIENT_GROUP_REMOVE_CLIENT_FAIL',
  ADD_MEMBERS: 'MY_CLIENT_GROUP_ADD_MEMBERS',
  ADD_MEMBERS_RESPONSE: 'MY_CLIENT_GROUP_ADD_MEMBERS_RESPONSE',
  ADD_MEMBERS_FAIL: 'MY_CLIENT_GROUP_ADD_MEMBERS_FAIL',
  REMOVE: 'MY_CLIENT_GROUP_REMOVE',
  REMOVE_RESPONSE: 'MY_CLIENT_GROUP_REMOVE_RESPONSE',
  REMOVE_FAIL: 'MY_CLIENT_GROUP_REMOVE_FAIL',
  CLEAR: 'MY_CLIENT_GROUP_CLEAR',
  LOGO_ERROR: 'MY_CLIENT_GROUP_LOGO_ERROR',
  MESSAGE_ADD_WORKOUT: 'MY_CLIENT_GROUP_MESSAGE_ADD_WORKOUT',
  PROGRESS_CLEAR: 'MY_CLIENT_GROUP_PROGRESS_CLEAR',

  SAVE_WORKOUT: 'MY_CLIENT_GROUP_SAVE_WORKOUT',
  SAVE_WORKOUT_RESPONSE: 'MY_CLIENT_GROUP_SAVE_WORKOUT_RESPONSE',
  SAVE_WORKOUT_FAIL: 'MY_CLIENT_GROUP_SAVE_WORKOUT_FAIL',
  REMOVE_WORKOUT: 'MY_CLIENT_GROUP_REMOVE_WORKOUT',
  REMOVE_WORKOUT_RESPONSE: 'MY_CLIENT_GROUP_REMOVE_WORKOUT_RESPONSE',
  REMOVE_WORKOUT_FAIL: 'MY_CLIENT_GROUP_REMOVE_WORKOUT_FAIL',

  GET_NOTES: 'CLIENT_GROUP_GET_NOTES',
  GET_NOTES_RESPONSE: 'CLIENT_GROUP_GET_NOTES_RESPONSE',
  GET_NOTES_FAILED: 'CLIENT_GROUP_GET_NOTES_FAILED',

  SAVE_NOTE: 'CLIENT_GROUP_SAVE_NOTE',
  SAVE_NOTE_RESPONSE: 'CLIENT_GROUP_SAVE_NOTE_RESPONSE',
  SAVE_NOTE_FAILED: 'CLIENT_GROUP_SAVE_NOTE_FAILED',

  REMOVE_NOTE: 'CLIENT_GROUP_REMOVE_NOTE',
  REMOVE_NOTE_RESPONSE: 'CLIENT_GROUP_REMOVE_NOTE_RESPONSE',
  REMOVE_NOTE_FAILED: 'CLIENT_GROUP_REMOVE_NOTE_FAILED',

  CREATE_NOTE: 'CLIENT_GROUP_CREATE_NOTE',
  CREATE_NOTE_RESPONSE: 'CLIENT_GROUP_CREATE_NOTE_RESPONSE',
  CREATE_NOTE_FAILED: 'CLIENT_GROUP_CREATE_NOTE_FAILED',
}

export const myProgram = {
  CREATE: 'MY_WORKOUT_CREATE',
  GET: 'MY_WORKOUT_GET',
  SAVE: 'MY_WORKOUT_SAVE',
  DUPLICATE: 'MY_WORKOUT_DUPLICATE',
  DELETE: 'MY_WORKOUT_DELETE',
  SHARE: 'MY_WORKOUT_SHARE',
  RESPONSE: 'MY_WORKOUT_RESPONSE',
  CREATE_RESPONSE: 'MY_WORKOUT_CREATE_RESPONSE',
  SAVE_RESPONSE: 'MY_WORKOUT_SAVE_RESPONSE',
  COPY_RESPONSE: 'MY_WORKOUT_COPY_RESPONSE',
  DUPLICATE_RESPONSE: 'MY_WORKOUT_DUPLICATE_RESPONSE',
  DELETE_RESPONSE: 'MY_WORKOUT_DELETE_RESPONSE',
  SHARE_RESPONSE: 'MY_WORKOUT_SHARE_RESPONSE',
  GET_FAIL: 'MY_WORKOUT_GET_FAILED',
  CREATE_FAIL: 'MY_WORKOUT_GET_FAILED',
  SAVE_FAIL: 'MY_WORKOUT_SAVE_FAILED',
  DUPLICATE_FAIL: 'MY_WORKOUT_DUPLICATE_FAILED',
  DELETE_FAIL: 'MY_WORKOUT_DELETE_FAILED',
  SHARE_FAIL: 'MY_WORKOUT_SHARE_FAILED',
  DISMISS_SHARING_DIALOG: 'MY_WORKOUT_DISMISS_SHARING_DIALOG',
  DISMISS_DUPLICATE_DIALOG: 'MY_WORKOUT_DISMISS_DUPLICATE_DIALOG',
  SET_MODE: 'MY_WORKOUT_SET_MODE',
  CLEAR: 'MY_WORKOUT_CLEAR',
}

export const myExercises = {
  GET: 'MY_EXERCISES_GET',
  RESPONSE: 'MY_EXERCISES_RESPONSE',
  GET_FAIL: 'MY_EXERCISES_GET_FAILED',
  ADD_NEW: 'MY_EXERCISES_ADD_NEW',

  GET_EXERCISES: 'MY_EXERCISES_GET_EXERCISES',
  GET_EXERCISES_RESPONSE: 'MY_EXERCISES_GET_EXERCISES_RESPONSE',
  GET_EXERCISES_FAILED: 'MY_EXERCISES_GET_EXERCISES_FAILED',

  SEARCH_FOR_EXERCISES: 'MY_EXERCISES_SEARCH_FOR_EXERCISES',
  SEARCH_FOR_EXERCISES_RESPONSE: 'MY_EXERCISES_SEARCH_FOR_EXERCISES_RESPONSE',
  SEARCH_FOR_EXERCISES_FAILED: 'MY_EXERCISES_SEARCH_FOR_EXERCISES_FAILED',
  SEARCH_FOR_EXERCISES_CLEAR: 'MY_EXERCISES_SEARCH_FOR_EXERCISES_CLEAR',

  FILTER_OUT_EXERCISE: "MY_GYM_EXERCISES_FILTER_OUT_EXERCISE"
}

export const myProgramShare = {
  GET: 'MY_SHARE_GET',
  DELETE: 'MY_SHARE_DELETE',
  RESPONSE: 'MY_SHARE_RESPONSE',
  DELETE_RESPONSE: 'MY_SHARE_DELETE_RESPONSE',
  GET_FAIL: 'MY_SHARE_GET_FAILED',
  DELETE_FAIL: 'MY_SHARE_DELETE_FAILED',
  SET_MODE: 'MY_SHARE_SET_MODE',
  UPDATE_MESSAGE: 'MY_SHARE_UPDATE_MESSAGE',
  UPDATE_MESSAGE_RESPONSE: 'MY_SHARE_UPDATE_MESSAGE_RESPONSE',
  UPDATE_MESSAGE_FAIL: 'MY_SHARE_UPDATE_MESSAGE_FAIL',
}

export const myPrivateExercises = {
  GET: 'MY_PRIVATE_EXERCISES_GET',
  RESPONSE: 'MY_PRIVATE_EXERCISES_RESPONSE',
  GET_FAIL: 'MY_PRIVATE_EXERCISES_GET_FAILED',
}

export const myPrivateExercise = {
  CLEAR: 'MY_PRIVATE_EXERCISE_CLEAR',
  CLEAR_ERROR: 'MY_PRIVATE_EXERCISE_CLEAR_ERROR',

  CREATE: 'MY_PRIVATE_EXERCISE_CREATE',
  CREATE_RESPONSE: 'MY_PRIVATE_EXERCISE_CREATE_RESPONSE',
  CREATE_FAIL: 'MY_PRIVATE_EXERCISE_CREATE_FAIL',

  GET: 'MY_PRIVATE_EXERCISE_GET',
  SAVE: 'MY_PRIVATE_EXERCISE_SAVE',
  DELETE: 'MY_PRIVATE_EXERCISE_DELETE',
  RESPONSE: 'MY_PRIVATE_EXERCISE_RESPONSE',
  SAVE_RESPONSE: 'MY_PRIVATE_EXERCISE_SAVE_RESPONSE',
  DELETE_RESPONSE: 'MY_PRIVATE_EXERCISE_DELETE_RESPONSE',
  GET_FAIL: 'MY_PRIVATE_EXERCISE_GET_FAILED',
  SAVE_FAIL: 'MY_PRIVATE_EXERCISE_SAVE_FAILED',
  DELETE_FAIL: 'MY_PRIVATE_EXERCISE_DELETE_FAILED',
  SET_MODE: 'MY_PRIVATE_EXERCISE_SET_MODE',
}

export const myGymPrograms = {
  GET: 'MY_GYM_WORKOUTS_GET',
  RESPONSE: 'MY_GYM_WORKOUTS_RESPONSE',
  GET_FAIL: 'MY_GYM_WORKOUTS_GET_FAILED',
  UPDATE_VISIBILITY: 'MY_GYM_WORKOUTS_UPDATE_VISIBILITY',
  CREATE: 'MY_GYM_WORKOUTS_CREATE',
  CREATE_RESPONSE: 'MY_GYM_WORKOUTS_CREATE_RESPONSE',
  CREATE_FAIL: 'MY_GYM_WORKOUTS_CREATE_FAIL',
  CLEAR_CREATE: 'MY_GYM_WORKOUTS_CLEAR_CREATE',
  FILTER_OUT_WORKOUT: 'MY_GYM_WORKOUTS_FILTER_OUT_WORKOUT',

  COPY: 'MY_GYM_WORKOUTS_COPY',
  COPY_RESPONSE: 'MY_GYM_WORKOUTS_COPY_RESPONSE',
  COPY_FAILED: 'MY_GYM_WORKOUTS_COPY_FAILED',

  IMPORT: 'MY_GYM_WORKOUTS_IMPORT',
  IMPORT_RESPONSE: 'MY_GYM_WORKOUTS_IMPORT_RESPONSE',
  IMPORT_FAILED: 'MY_GYM_WORKOUTS_IMPORT_FAILED',
}

export const myGymExercises = {
  GET: 'MY_GYM_EXERCISES_GET',
  RESPONSE: 'MY_GYM_EXERCISES_RESPONSE',
  GET_FAIL: 'MY_GYM_EXERCISES_GET_FAILED',
  ADD_NEW: 'MY_GYM_EXERCISES_ADD_NEW',

  GET_EXERCISES: 'MY_GYM_EXERCISES_GET_EXERCISES',
  GET_EXERCISES_RESPONSE: 'MY_GYM_EXERCISES_GET_EXERCISES_RESPONSE',
  GET_EXERCISES_FAILED: 'MY_GYM_EXERCISES_GET_EXERCISES_FAILED',

  SEARCH_FOR_EXERCISES: 'MY_GYM_EXERCISES_SEARCH_FOR_EXERCISES',
  SEARCH_FOR_EXERCISES_RESPONSE: 'MY_GYM_EXERCISES_SEARCH_FOR_EXERCISES_RESPONSE',
  SEARCH_FOR_EXERCISES_FAILED: 'MY_GYM_EXERCISES_SEARCH_FOR_EXERCISES_FAILED',
  SEARCH_FOR_EXERCISES_CLEAR: 'MY_GYM_EXERCISES_SEARCH_FOR_EXERCISES_CLEAR',

  FILTER_OUT_EXERCISE: "MY_GYM_EXERCISES_FILTER_OUT_EXERCISE",

  SILENT_GET_COUNT: "MY_GYM_EXERCISES_SILENT_GET_COUNT"
}

export const myGymMessages = {
  GET_MESSAGES: 'MY_GYM_GET_MESSAGES',
  GET_MESSAGES_RESPONSE: 'MY_GYM_GET_MESSAGES_RESPONSE',
  GET_MESSAGES_FAIL: 'MY_GYM_GET_MESSAGES_FAILED',

  GET_MORE_MESSAGES: 'MY_GYM_GET_MORE_MESSAGES',
  GET_MORE_MESSAGES_RESPONSE: 'MY_GYM_GET_MESSAGES_MORE_RESPONSE',
  GET_MORE_MESSAGES_FAIL: 'MY_GYM_GET_MESSAGES_MORE_FAILED',

  SEND_MESSAGE: 'MY_GYM_SEND_MESSAGE',
  SEND_MESSAGE_RESPONSE: 'MY_GYM_SEND_MESSAGE_RESPONSE',
  SEND_MESSAGE_FAIL: 'MY_GYM_SEND_MESSAGE_FAILED',

  REMOVE_MESSAGE: 'MY_GYM_REMOVE_MESSAGE',
  REMOVE_MESSAGE_RESPONSE: 'MY_GYM_REMOVE_MESSAGE_RESPONSE',
  REMOVE_MESSAGE_FAIL: 'MY_GYM_REMOVE_MESSAGE_FAILED',
}

export const myGymMemberActivity = {
  GET_ACTIVITY: 'MY_GYM_GET_ACTIVITY',
  GET_ACTIVITY_RESPONSE: 'MY_GYM_GET_ACTIVITY_RESPONSE',
  GET_ACTIVITY_FAIL: 'MY_GYM_GET_ACTIVITY_FAILED',
}

export const myGymProgram = {
  GET: 'MY_GYM_WORKOUT_GET',
  SAVE: 'MY_GYM_WORKOUT_SAVE',
  DELETE: 'MY_GYM_WORKOUT_DELETE',
  DUPLICATE: 'MY_GYM_WORKOUT_DUPLICATE',
  RESPONSE: 'MY_GYM_WORKOUT_RESPONSE',
  SAVE_RESPONSE: 'MY_GYM_WORKOUT_SAVE_RESPONSE',
  DELETE_RESPONSE: 'MY_GYM_WORKOUT_DELETE_RESPONSE',
  COPY_RESPONSE: 'MY_GYM_WORKOUT_COPY_RESPONSE',
  DUPLICATE_RESPONSE: 'MY_GYM_WORKOUT_DUPLICATE_RESPONSE',
  GET_FAIL: 'MY_GYM_WORKOUT_GET_FAILED',
  SAVE_FAIL: 'MY_GYM_WORKOUT_SAVE_FAILED',
  DELETE_FAIL: 'MY_GYM_WORKOUT_DELETE_FAILED',
  DUPLICATE_FAIL: 'MY_GYM_WORKOUT_DUPLICATE_FAILED',
  DISMISS_DUPLICATE_DIALOG: 'MY_WORKOUT_DISMISS_DUPLICATE_DIALOG',
  SET_MODE: 'MY_GYM_WORKOUT_SET_MODE',

  SENDCOPY: 'MY_GYM_WORKOUT_SENDCOPY',
  SENDCOPY_RESPONSE: 'MY_GYM_WORKOUT_SENDCOPY_RESPONSE',
  SENDCOPY_COMPLETE: 'MY_GYM_WORKOUT_SENDCOPY_COMPLETE',
}

export const myGymExercise = {
  CLEAR: 'MY_GYM_EXERCISE_CLEAR',
  CLEAR_ERROR: 'MY_GYM_EXERCISE_CLEAR_ERROR',

  CREATE: 'MY_GYM_EXERCISE_CREATE',
  CREATE_RESPONSE: 'MY_GYM_EXERCISE_CREATE_RESPONSE',
  CREATE_FAIL: 'MY_GYM_EXERCISE_CREATE_FAIL',

  GET: 'MY_GYM_EXERCISE_GET',
  SAVE: 'MY_GYM_EXERCISE_SAVE',
  DELETE: 'MY_GYM_EXERCISE_DELETE',
  RESPONSE: 'MY_GYM_EXERCISE_RESPONSE',
  SAVE_RESPONSE: 'MY_GYM_EXERCISE_SAVE_RESPONSE',
  DELETE_RESPONSE: 'MY_GYM_EXERCISE_DELETE_RESPONSE',
  GET_FAIL: 'MY_GYM_EXERCISE_GET_FAILED',
  SAVE_FAIL: 'MY_GYM_EXERCISE_SAVE_FAILED',
  DELETE_FAIL: 'MY_GYM_EXERCISE_DELETE_FAILED',
  SET_MODE: 'MY_GYM_EXERCISE_SET_MODE',

  COPY: 'MY_GYM_EXERCISE_COPY',
  COPY_RESPONSE: 'MY_GYM_EXERCISE_COPY_RESPONSE',
  COPY_FAILED: 'MY_GYM_EXERCISE_COPY_FAILED',

  WORK: 'MY_GYM_EXERCISE_WORK',
  WORK_RESPONSE: 'MY_GYM_EXERCISE_WORK_RESPONSE',
  CLEAR_WORK: 'MY_GYM_EXERCISE_CLEAR_WORK',
}

export const managePermissions = {
  GET_USERS: 'MANAGE_PERMISSIONS_GET_USERS',
  GET_USERS_RESPONSE: 'MANAGE_PERMISSIONS_GET_USERS_RESPONSE',
  GET_USERS_FAILED: 'MANAGE_PERMISSIONS_GET_USERS_FAILED',

  ADD_PERMISSION: 'MANAGE_PERMISSIONS_ADD_PERMISSION',
  ADD_PERMISSION_RESPONSE: 'MANAGE_PERMISSIONS_ADD_PERMISSION_RESPONSE',
  ADD_PERMISSION_FAILED: 'MANAGE_PERMISSIONS_ADD_PERMISSION_FAILED',

  REMOVE_PERMISSION: 'MANAGE_PERMISSIONS_REMOVE_PERMISSION',
  REMOVE_PERMISSION_RESPONSE: 'MANAGE_PERMISSIONS_REMOVE_PERMISSION_RESPONSE',
  REMOVE_PERMISSION_FAILED: 'MANAGE_PERMISSIONS_REMOVE_PERMISSION_FAIL',

  CLEAR_ERRORS: 'MANAGE_PERMISSIONS_CLEAR_ERRORS',
}

export const importCSV = {
  GET: 'IMPORT_CSV_GET',
  GET_RESPONSE: 'IMPORT_CSV_GET_RESPONSE',
  GET_FAILED: 'IMPORT_CSV_GET_FAILED',
  CLEAR: 'IMPORT_CSV_CLEAR',
}

export const manageMembers = {
  GET_NUMBER_OF_MEMBERS: 'MANAGE_MEMBERS_GET_NUMBER_OF_MEMBERS',
  GET_NUMBER_OF_MEMBERS_RESPONSE:
    'MANAGE_MEMBERS_GET_MEMBERS_NUMBER_OF_RESPONSE',
  GET_NUMBER_OF_MEMBERS_FAILED: 'MANAGE_MEMBERS_GET_MEMBERS_NUMBER_OF_FAILED',

  GET_MEMBERS: 'MANAGE_MEMBERS_GET_MEMBERS',
  GET_MEMBERS_RESPONSE: 'MANAGE_MEMBERS_GET_MEMBERS_RESPONSE',
  GET_MEMBERS_FAILED: 'MANAGE_MEMBERS_GET_MEMBERS_FAILED',

  SEARCH_FOR_MEMBERS: 'MANAGE_MEMBERS_SEARCH_FOR_MEMBERS',
  SEARCH_FOR_MEMBERS_RESPONSE: 'MANAGE_MEMBERS_SEARCH_FOR_MEMBERS_RESPONSE',
  SEARCH_FOR_MEMBERS_FAILED: 'MANAGE_MEMBERS_SEARCH_FOR_MEMBERS_FAILED',
  SEARCH_FOR_MEMBERS_CLEAR: 'MANAGE_MEMBERS_SEARCH_FOR_MEMBERS_CLEAR',

  SAVE_MEMBER_RESPONSE: 'MANAGE_MEMBER_SAVE_MEMBER_RESPONSE',
  REMOVE_MEMBER_RESPONSE: 'MANAGE_MEMBERS_REMOVE_MEMBER_RESPONSE',

  ADD_MEMBERS: 'MANAGE_MEMBERS_ADD_MEMBERS',
  ADD_MEMBERS_RESPONSE: 'MANAGE_MEMBERS_ADD_MEMBERS_RESPONSE',
  ADD_MEMBERS_FAILED: 'MANAGE_MEMBERS_ADD_MEMBERS_FAILED',

  UPDATE_MEMBERS: 'MANAGE_MEMBERS_UPDATE_MEMBERS',
  UPDATE_MEMBERS_RESPONSE: 'MANAGE_MEMBERS_UPDATE_MEMBERS_RESPONSE',
  UPDATE_MEMBERS_FAILED: 'MANAGE_MEMBERS_UPDATE_MEMBERS_FAILED',

  WORK: 'MY_GYM_MEMBERS_WORK',
  WORK_RESPONSE: 'MY_GYM_MEMBERS_WORK_RESPONSE',
  CLEAR_WORK: 'MY_GYM_MEMBERS_CLEAR_WORK',

  CLEAR_ERRORS: 'MANAGE_MEMBERS_CLEAR_ERRORS',

  SET_FILTER: 'MANAGE_MEMBERS_SET_FILTER',
}

export const manageMember = {
  GET_MEMBER: 'MANAGE_MEMBER_GET_MEMBER',
  GET_MEMBER_RESPONSE: 'MANAGE_MEMBER_GET_MEMBER_RESPONSE',
  GET_MEMBER_FAILED: 'MANAGE_MEMBER_GET_MEMBER_FAILED',

  SAVE_MEMBER: 'MANAGE_MEMBER_SAVE_MEMBER',
  SAVE_MEMBER_RESPONSE: 'MANAGE_MEMBER_SAVE_MEMBER_RESPONSE',
  SAVE_MEMBER_FAILED: 'MANAGE_MEMBER_SAVE_MEMBER_FAILED',

  REMOVE_MEMBER: 'MANAGE_MEMBER_REMOVE_MEMBER',
  REMOVE_MEMBER_RESPONSE: 'MANAGE_MEMBER_REMOVE_MEMBER_RESPONSE',
  REMOVE_MEMBER_STATUS_RESPONSE: 'MANAGE_MEMBER_REMOVE_MEMBER_STATUS_RESPONSE',
  REMOVE_MEMBER_FAILED: 'MANAGE_MEMBER_REMOVE_MEMBER_FAIL',

  GET_NOTES: 'MANAGE_MEMBER_GET_NOTES',
  GET_NOTES_RESPONSE: 'MANAGE_MEMBER_GET_NOTES_RESPONSE',
  GET_NOTES_FAILED: 'MANAGE_MEMBER_GET_NOTES_FAILED',

  SAVE_NOTE: 'MANAGE_MEMBER_SAVE_NOTE',
  SAVE_NOTE_RESPONSE: 'MANAGE_MEMBER_SAVE_NOTE_RESPONSE',
  SAVE_NOTE_FAILED: 'MANAGE_MEMBER_SAVE_NOTE_FAILED',

  REMOVE_NOTE: 'MANAGE_MEMBER_REMOVE_NOTE',
  REMOVE_NOTE_RESPONSE: 'MANAGE_MEMBER_REMOVE_NOTE_RESPONSE',
  REMOVE_NOTE_FAILED: 'MANAGE_MEMBER_REMOVE_NOTE_FAILED',

  CREATE_NOTE: 'MANAGE_MEMBER_CREATE_NOTE',
  CREATE_NOTE_RESPONSE: 'MANAGE_MEMBER_CREATE_NOTE_RESPONSE',
  CREATE_NOTE_FAILED: 'MANAGE_MEMBER_CREATE_NOTE_FAILED',

  GET_WORKOUTS: 'MANAGE_MEMBER_GET_WORKOUTS',
  GET_WORKOUTS_RESPONSE: 'MANAGE_MEMBER_GET_WORKOUTS_RESPONSE',
  GET_WORKOUTS_FAILED: 'MANAGE_MEMBER_GET_WORKOUTS_FAILED',

  SAVE_WORKOUT: 'MANAGE_MEMBER_SAVE_WORKOUT',
  SAVE_WORKOUT_RESPONSE: 'MANAGE_MEMBER_SAVE_WORKOUT_RESPONSE',
  SAVE_WORKOUT_FAILED: 'MANAGE_MEMBER_SAVE_WORKOUT_FAILED',

  REMOVE_WORKOUT: 'MANAGE_MEMBER_REMOVE_WORKOUT',
  REMOVE_WORKOUT_RESPONSE: 'MANAGE_MEMBER_REMOVE_WORKOUT_RESPONSE',
  REMOVE_WORKOUT_FAILED: 'MANAGE_MEMBER_REMOVE_WORKOUT_FAILED',

  CREATE_WORKOUT: 'MANAGE_MEMBER_CREATE_WORKOUT',
  CREATE_WORKOUT_RESPONSE: 'MANAGE_MEMBER_CREATE_WORKOUT_RESPONSE',
  CREATE_WORKOUT_FAILED: 'MANAGE_MEMBER_CREATE_WORKOUT_FAILED',

  PROGRESS_CLEAR: 'MANAGE_MEMBER_PROGRESS_CLEAR',
  CLEAR: 'MANAGE_MEMBER_CLEAR',
  CLEAR_ERRORS: 'MANAGE_MEMBER_CLEAR_ERRORS',

  SET_UNREAD_MESSAGES: 'MANAGE_MEMBER_SET_UNREAD_MESSAGES',
}

export const manageSuPermissions = {
  GET_USERS: 'MANAGE_SU_PERMISSIONS_GET_USERS',
  GET_USERS_RESPONSE: 'MANAGE_SU_PERMISSIONS_GET_USERS_RESPONSE',
  GET_USERS_FAILED: 'MANAGE_SU_PERMISSIONS_GET_USERS_FAILED',

  ADD_PERMISSION: 'MANAGE_SU_PERMISSIONS_ADD_PERMISSION',
  ADD_PERMISSION_RESPONSE: 'MANAGE_SU_PERMISSIONS_ADD_PERMISSION_RESPONSE',
  ADD_PERMISSION_FAILED: 'MANAGE_SU_PERMISSIONS_ADD_PERMISSION_FAILED',

  REMOVE_PERMISSION: 'MANAGE_SU_PERMISSIONS_REMOVE_PERMISSION',
  REMOVE_PERMISSION_RESPONSE:
    'MANAGE_SU_PERMISSIONS_REMOVE_PERMISSION_RESPONSE',
  REMOVE_PERMISSION_FAILED: 'MANAGE_SU_PERMISSIONS_REMOVE_PERMISSION_FAIL',

  CLEAR_ERRORS: 'MANAGE_SU_PERMISSIONS_CLEAR_ERRORS',
}

export const clientWorkouts = {
  GET: 'WORKOUTS_GET',
  RESPONSE: 'WORKOUTS_RESPONSE',
  GET_FAILED: 'WORKOUTS_GET_FAILED',
  GET_MORE: 'WORKOUTS_GET_MORE',
  GET_MORE_RESPONSE: 'WORKOUTS_GET_MORE_RESPONSE',
  GET_MORE_FAILED: 'WORKOUTS_GET_MORE_FAILED',
}

export const memberWorkouts = {
  GET: 'MEMBER_WORKOUTS_GET',
  RESPONSE: 'MEMBER_WORKOUTS_RESPONSE',
  GET_FAILED: 'MEMBER_WORKOUTS_GET_FAILED',
  GET_MORE: 'MEMBER_WORKOUTS_GET_MORE',
  GET_MORE_RESPONSE: 'MEMBER_WORKOUTS_GET_MORE_RESPONSE',
  GET_MORE_FAILED: 'MEMBER_WORKOUTS_GET_MORE_FAILED',
}

export const gymStatus = {
  GET: 'GYM_STATUS_GET',
  GET_RESPONSE: 'GYM_STATUS_RESPONSE',
  GET_FAIL: 'GYM_STATUS_GET_FAIL',
  CLEAR: 'GYM_STATUS_CLEAR',
}

export const gymBannerStatus = {
  GET: 'GYM_STATUS_BANNER_GET',
  GET_RESPONSE: 'GYM_STATUS_BANNER_RESPONSE',
  GET_FAIL: 'GYM_STATUS_BANNER_GET_FAIL',
  CLEAR: 'GYM_STATUS_BANNER_CLEAR',
}

export const gymUtilization = {
  GET: 'GYM_UTILIZATION_GET',
  CALCULATING: 'GYM_UTILIZATION_CALCULATING',
  RESPONSE: 'GYM_UTILIZATION_RESPONSE',
  FAILURE: 'GYM_UTILIZATION_FAILURE',
  SELECT_MACHINE: 'GYM_UTILIZATION_SELECT_MACHINE',
  TOGGLE_SELECT_ALL_MACHINES: 'GYM_UTILIZATION_TOGGLE_SELECT_ALL_MACHINES',
  SET_DATE: 'GYM_UTILIZATION_SET_DATE',
  SET_SORT: 'GYM_UTILIZATION_SET_SORT',

  GET_RETENTION: 'GYM_UTILIZATION_GET_RETENTION',
  RESPONSE_RETENTION: 'GYM_UTILIZATION_RESPONSE_RETENTION',
  FAILURE_RETENTION: 'GYM_UTILIZATION_FAILURE_RETENTION',

  GET_TAPS: 'GYM_UTILIZATION_GET_TAPS',
  CALCULATING_TAPS: 'GYM_UTILIZATION_CALCULATING_TAPS',
  GET_TAPS_RESPONSE: 'GYM_UTILIZATION_GET_TAPS_RESPONSE',
  GET_TAPS_FAIL: 'GYM_UTILIZATION_GET_TAPS_FAIL',
  SELECT_PUCK: 'GYM_UTILIZATION_SELECT_PUCK',
  TOGGLE_SELECT_ALL_PUCKS: 'GYM_UTILIZATION_TOGGLE_SELECT_ALL_PUCKS',
}

export const gymContentStatistics = {
  GET_ROUTINES: 'GYM_CONTENT_STATISTICS_GET_ROUTINES',
  GET_ROUTINES_RESPONSE: 'GYM_CONTENT_STATISTICS_GET_ROUTINES_RESPONSE',
  GET_ROUTINES_FAIL: 'GYM_CONTENT_STATISTICS_GET_ROUTINES_FAIL',
  GET_ROUTINES_DISTRIBUTION: 'GYM_CONTENT_STATISTICS_GET_ROUTINES_DISTRIBUTION',
  GET_ROUTINES_DISTRIBUTION_RESPONSE: 'GYM_CONTENT_STATISTICS_GET_ROUTINES_DISTRIBUTION_RESPONSE',
  GET_ROUTINES_DISTRIBUTION_FAIL: 'GYM_CONTENT_STATISTICS_GET_ROUTINES_DISTRIBUTION_FAIL',
  SELECT_ROUTINE: 'GYM_CONTENT_STATISTICS_SELECT_ROUTINE',
  TOGGLE_SELECT_ALL_ROUTINES: 'GYM_CONTENT_STATISTICS_TOGGLE_SELECT_ALL_ROUTINES',

  GET_EXERCISES: 'GYM_CONTENT_STATISTICS_GET_EXERCISES',
  GET_EXERCISES_RESPONSE: 'GYM_CONTENT_STATISTICS_GET_EXERCISES_RESPONSE',
  GET_EXERCISES_FAIL: 'GYM_CONTENT_STATISTICS_GET_EXERCISES_FAIL',
  SELECT_EXERCISE: 'GYM_CONTENT_STATISTICS_SELECT_EXERCISE',
  TOGGLE_SELECT_ALL_EXERCISES: 'GYM_CONTENT_STATISTICS_TOGGLE_SELECT_ALL_EXERCISES',

  SET_DATE: 'GYM_CONTENT_STATISTICS_SET_DATE',
  SET_SORT: 'GYM_CONTENT_STATISTICS_SET_SORT',
}

export const gymMemberStatistics = {
  SET_DATE: 'GYM_MEMBER_STATISTICS_SET_DATE',
  GET_RETENTION: 'GYM_MEMBER_STATISTICS_GET_RETENTION',
  RESPONSE_RETENTION: 'GYM_MEMBER_STATISTICS_RESPONSE_RETENTION',
  FAILURE_RETENTION: 'GYM_MEMBER_STATISTICS_FAILURE_RETENTION',
  GET_ALL_RETENTION: 'GYM_MEMBER_STATISTICS_GET_ALL_RETENTION',
  RESPONSE_ALL_RETENTION: 'GYM_MEMBER_STATISTICS_RESPONSE_ALL_RETENTION',
  FAILURE_ALL_RETENTION: 'GYM_MEMBER_STATISTICS_FAILURE_ALL_RETENTION',
  GET_INCREMENT: 'GYM_MEMBER_STATISTICS_GET_INCREMENT',
  RESPONSE_INCREMENT: 'GYM_MEMBER_STATISTICS_RESPONSE_INCREMENT',
  FAILURE_INCREMENT: 'GYM_MEMBER_STATISTICS_FAILURE_INCREMENT',
  SET_SORT: 'GYM_MEMBER_STATISTICS_SET_SORT',
  SET_PICKER_SORT: 'GYM_MEMBER_STATISTICS_SET_PICKER_SORT',
  SET_LANG_FORMAT: 'GYM_MEMBER_STATISTICS_SET_LANG_FORMAT'
}

export const gymHourActivity = {
  SET_DATE: 'GYM_HOUR_ACTIVITY_SET_DATE',
  GET: 'GYM_HOUR_ACTIVITY_GET',
  CALCULATING: 'GYM_HOUR_ACTIVITY_CALCULATING',
  RESPONSE: 'GYM_HOUR_ACTIVITY_RESPONSE',
  FAILURE: 'GYM_HOUR_ACTIVITY_FAILURE',
}

export const partners = {
  GET: 'PARTNERS_GET',
  RESPONSE: 'PARTNERS_RESPONSE',
  GET_FAIL: 'PARTNERS_GET_FAILED',
}

export const partner = {
  GET: 'PARTNER_EVENTS_GET',
  GET_RESPONSE: 'PARTNER_EVENTS_RESPONSE',
  GET_FAIL: 'PARTNER_EVENTS_GET_FAIL',
  SAVE: 'PARTNER_EVENTS_SAVE',
  SAVE_RESPONSE: 'PARTNER_EVENTS_SAVE_RESPONSE',
  SAVE_FAIL: 'PARTNER_EVENTS_SAVE_FAIL',
  DELETE: 'PARTNER_EVENTS_DELETE',
  DELETE_RESPONSE: 'PARTNER_EVENTS_DELETE_RESPONSE',
  DELETE_FAIL: 'PARTNER_EVENTS_DELETE_FAIL',
  GET_USERS: 'PARTNER_EVENTS_GET_USERS',
  GET_USERS_RESPONSE: 'PARTNER_EVENTS_GET_USERS_RESPONSE',
  GET_USERS_FAIL: 'PARTNER_EVENTS_GET_USERS_FAIL',
  ADD_USER: 'PARTNER_EVENTS_ADD_USER',
  ADD_USER_RESPONSE: 'PARTNER_EVENTS_ADD_USER_RESPONSE',
  ADD_USER_FAIL: 'PARTNER_EVENTS_ADD_USER_FAIL',
  REMOVE_USER: 'PARTNER_EVENTS_REMOVE_USER',
  REMOVE_USER_RESPONSE: 'PARTNER_EVENTS_REMOVE_USER_RESPONSE',
  REMOVE_USER_FAIL: 'PARTNER_EVENTS_ADD_USER_FAIL',
  LOGO_ERROR: 'PARTNER_EVENTS_LOGO_ERROR',
  CLEAR: 'PARTNER_EVENTS_CLEAR',
  GET_DAS: 'PARTNER_EVENTS_GET_DAS',
  DAS_RESPONSE: 'PARTNER_EVENTS_DAS_RESPONSE',
  DAS_FAIL: 'PARTNER_EVENTS_DAS_FAIL',
  CLEAR_DAS: 'PARTNER_EVENTS_CLEAR_DAS',
}

export const partnerGym = {
  GET: 'PARTNER_GYM_GET',
  GET_RESPONSE: 'PARTNER_GYM_RESPONSE',
  GET_FAIL: 'PARTNER_GYM_GET_FAIL',
  CREATE: 'PARTNER_GYM_CREATE',
  CREATE_RESPONSE: 'PARTNER_GYM_CREATE_RESPONSE',
  CREATE_FAIL: 'PARTNER_GYM_CREATE_FAIL',
  SAVE: 'PARTNER_GYM_SAVE',
  SAVE_RESPONSE: 'PARTNER_GYM_SAVE_RESPONSE',
  SAVE_FAIL: 'PARTNER_GYM_SAVE_FAILED',
  CLEAR: 'PARTNER_GYM_CLEAR',
}

export const clients = {
  GET: 'CLIENTS_GET',
  RESPONSE: 'CLIENTS_RESPONSE',
  GET_FAIL: 'CLIENTS_GET_FAILED',
  CANCEL_SET: 'CLIENTS_CANCEL_SET',
  CANCEL_CLEAR: 'CLIENTS_CANCEL_CLEAR',

  GET_MORE_CLIENTS: 'CLIENTS_GET_MORE_CLIENTS',
  GET_MORE_CLIENTS_RESPONSE: 'CLIENTS_GET_MORE_CLIENTS_RESPONSE',
  GET_MORE_CLIENTS_FAILED: 'CLIENTS_GET_MORE_CLIENTS_FAILED',

  SEARCH_FOR_CLIENTS: 'CLIENTS_SEARCH_FOR_CLIENTS',
  SEARCH_FOR_CLIENTS_RESPONSE: 'CLIENTS_SEARCH_FOR_CLIENTS_RESPONSE',
  SEARCH_FOR_CLIENTS_FAILED: 'CLIENTS_SEARCH_FOR_CLIENTS_FAILED',
  SEARCH_FOR_CLIENTS_CLEAR: 'CLIENTS_SEARCH_FOR_CLIENTS_CLEAR',

  GET_ALL_UNREAD_MESSAGES_RESPONSE: 'CLIENTS_GET_ALL_UNREAD_MESSAGES_RESPONSE',
  GET_CLIENT_COUNT: 'CLIENT_GET_CLIENT_COUNT',
  GET_CLIENT_COUNT_RESPONSE: 'CLIENT_GET_CLIENT_COUNT_RESPONSE',
  GET_CLIENT_COUNT_FAILED: 'CLIENT_GET_CLIENT_COUNT_FAILED',

  ADD_CLIENT: 'CLIENT_ADD_CLIENT',
  ADD_CLIENT_RESPONSE: 'CLIENT_ADD_CLIENT_RESPONSE',
  ADD_CLIENT_FAILED: 'CLIENT_ADD_CLIENT_FAILED',
  ADD_CLIENT_CLEAR: 'CLIENT_ADD_CLIENT_CLEAR',
}

export const client = {
  GET: 'CLIENT_GET',
  RESPONSE: 'CLIENT_RESPONSE',
  GET_FAIL: 'CLIENT_GET_FAILED',
  UPDATE: 'CLIENT_UPDATE',
  UPDATE_RESPONSE: 'CLIENT_UPDATE_RESPONSE',
  UPDATE_FAIL: 'CLIENT_UPDATE_FAILED',
  SHARE: 'CLIENT_SHARE',
  SHARE_RESPONSE: 'CLIENT_SHARE_RESPONSE',
  SHARE_FAIL: 'CLIENT_SHARE_FAILED',
  REMOVE: 'CLIENT_REMOVE',
  REMOVE_RESPONSE: 'CLIENT_REMOVE_RESPONSE',
  REMOVE_FAIL: 'CLIENT_REMOVE_FAILED',
  REMOVE_SHARE: 'CLIENT_REMOVE_SHARE',
  REMOVE_SHARE_RESPONSE: 'CLIENT_REMOVE_SHARE_RESPONSE',
  REMOVE_SHARE_FAIL: 'CLIENT_REMOVE_SHARE_FAILED',
  SET_READ_ALL: "CLIENT_SET_READ_ALL",
  GET_SHARES: "CLIENT_GET_SHARES",
  SHARES_RESPONSE: "CLIENT_SHARES_RESPONSE",
  SHARES_FAILED: "CLIENT_SHARES_FAILED",
  ADD_SHARE: "CLIENT_ADD_SHARE",
  PROGRESS_CLEAR: "CLIENT_PROGRESS_CLEAR",

  SAVE_SHARE: 'CLIENT_SAVE_SHARE',
  SAVE_SHARE_RESPONSE: 'CLIENT_SAVE_SHARE_RESPONSE',
  SAVE_SHARE_FAILED: 'CLIENT_SAVE_SHARE_FAILED',

  GET_NOTES: 'CLIENT_GET_NOTES',
  GET_NOTES_RESPONSE: 'CLIENT_GET_NOTES_RESPONSE',
  GET_NOTES_FAILED: 'CLIENT_GET_NOTES_FAILED',

  SAVE_NOTE: 'CLIENT_SAVE_NOTE',
  SAVE_NOTE_RESPONSE: 'CLIENT_SAVE_NOTE_RESPONSE',
  SAVE_NOTE_FAILED: 'CLIENT_SAVE_NOTE_FAILED',

  REMOVE_NOTE: 'CLIENT_REMOVE_NOTE',
  REMOVE_NOTE_RESPONSE: 'CLIENT_REMOVE_NOTE_RESPONSE',
  REMOVE_NOTE_FAILED: 'CLIENT_REMOVE_NOTE_FAILED',

  CREATE_NOTE: 'CLIENT_CREATE_NOTE',
  CREATE_NOTE_RESPONSE: 'CLIENT_CREATE_NOTE_RESPONSE',
  CREATE_NOTE_FAILED: 'CLIENT_CREATE_NOTE_FAILED',
}

export const myGymProgramCollections = {
  GET: 'MY_GYM_WORKOUT_COLLECTIONS_GET',
  RESPONSE: 'MY_GYM_WORKOUT_COLLECTIONS_RESPONSE',
  GET_FAIL: 'MY_GYM_WORKOUT_COLLECTIONS_GET_FAILED',
  UPDATE_VISIBILITY: 'MY_GYM_WORKOUT_COLLECTIONS_UPDATE_VISIBILITY',
  FILTER_OUT_COLLECTION: 'MY_GYM_WORKOUT_COLLECTIONS_FILTER_OUT_COLLECTION',

  COPY: 'MY_GYM_WORKOUT_COLLECTIONS_COPY',
  COPY_RESPONSE: 'MY_GYM_WORKOUT_COLLECTIONS_COPY_RESPONSE',
  COPY_FAILED: 'MY_GYM_WORKOUT_COLLECTIONS_COPY_FAILED',
}

export const myGymProgramCollection = {
  GET: 'MY_GYM_WORKOUT_COLLECTION_GET',
  SAVE: 'MY_GYM_WORKOUT_COLLECTION_SAVE',
  DELETE: 'MY_GYM_WORKOUT_COLLECTION_DELETE',
  DUPLICATE: 'MY_GYM_WORKOUT_COLLECTION_DUPLICATE',
  RESPONSE: 'MY_GYM_WORKOUT_COLLECTION_RESPONSE',
  SAVE_RESPONSE: 'MY_GYM_WORKOUT_COLLECTION_SAVE_RESPONSE',
  DELETE_RESPONSE: 'MY_GYM_WORKOUT_COLLECTION_DELETE_RESPONSE',
  DUPLICATE_RESPONSE: 'MY_GYM_WORKOUT_COLLECTION_DUPLICATE_RESPONSE',
  GET_FAIL: 'MY_GYM_WORKOUT_COLLECTION_GET_FAILED',
  SAVE_FAIL: 'MY_GYM_WORKOUT_COLLECTION_SAVE_FAILED',
  DELETE_FAIL: 'MY_GYM_WORKOUT_COLLECTION_DELETE_FAILED',
  DUPLICATE_FAIL: 'MY_GYM_WORKOUT_COLLECTION_DUPLICATE_FAILED',
  DISMISS_DUPLICATE_DIALOG:
    'MY_GYM_WORKOUT_COLLECTION_DISMISS_DUPLICATE_DIALOG',
  SET_MODE: 'MY_GYM_WORKOUT_COLLECTION_SET_MODE',

  WORK: 'MY_GYM_WORKOUT_COLLECTION_WORK',
  WORK_RESPONSE: 'MY_GYM_WORKOUT_COLLECTION_WORK_RESPONSE',
  CLEAR_WORK: 'MY_GYM_WORKOUT_COLLECTION_CLEAR_WORK',
}

export const myGymCategories = {
  GET: 'MY_GYM_CATEGORIES_GET',
  RESPONSE: 'MY_GYM_CATEGORIES_RESPONSE',
  GET_FAIL: 'MY_GYM_CATEGORIES_GET_FAILED',
  SAVE: 'MY_GYM_CATEGORIES_SAVE',
  SAVE_FAIL: 'MY_GYM_CATEGORIES_SAVE_FAILED',

  UPDATE_WORKOUT: 'MY_GYM_CATEGORIES_UPDATE_WORKOUT',
  REMOVE_ITEM: 'MY_GYM_CATEGORIES_REMOVE_ITEM',
}

export const profileSettings = {
  PROFILE_SETTINGS_SAVE: 'PROFILE_SETTINGS_SAVE',
  PROFILE_SETTINGS_RESPONE: 'PROFILE_SETTINGS_RESPONE',
  PROFILE_SETTINGS_ERROR: 'PROFILE_SETTINGS_ERROR',

  PROFILE_UNIT_SETTINGS_SAVE: 'PROFILE_UNIT_SETTINGS_SAVE',
  PROFILE_UNIT_SETTINGS_RESPONE: 'PROFILE_UNIT_SETTINGS_RESPONE',
  PROFILE_UNIT_SETTINGS_ERROR: 'PROFILE_UNIT_SETTINGS_ERROR',

  PROFILE_LANGUAGE_SETTINGS_SAVE: 'PROFILE_LANGUAGE_SETTINGS_SAVE',
  PROFILE_LANGUAGE_SETTINGS_RESPONE: 'PROFILE_LANGUAGE_SETTINGS_RESPONE',
  PROFILE_LANGUAGE_SETTINGS_ERROR: 'PROFILE_LANGUAGE_SETTINGS_ERROR',

  PROFILE_PICTURE_SAVE: 'PROFILE_PICTURE_SAVE',
  PROFILE_PICTURE_RESPONE: 'PROFILE_PICTURE_RESPONE',
  PROFILE_PICTURE_ERROR: 'PROFILE_PICTURE_ERROR',

  PROFILE_TERMS_SAVE: 'PROFILE_TERMS_SAVE',
  PROFILE_TERMS_RESPONE: 'PROFILE_TERMS_RESPONE',
  PROFILE_TERMS_ERROR: 'PROFILE_TERMS_ERROR',

  PROFILE_PT_NOTIFICATION_SAVE: 'PROFILE_PT_NOTIFICATION_SAVE',
  PROFILE_PT_NOTIFICATION_RESPONE: 'PROFILE_PT_NOTIFICATION_RESPONE',
  PROFILE_PT_NOTIFICATION_ERROR: 'PROFILE_PT_NOTIFICATION_ERROR',

  PROFILE_SEND_FEEDBACK: 'PROFILE_SEND_FEEDBACK',
  PROFILE_SEND_FEEDBACK_RESPONE: 'PROFILE_SEND_FEEDBACK_RESPONE',
  PROFILE_SEND_FEEDBACK_ERROR: 'PROFILE_SEND_FEEDBACK_ERROR',

  PROFILE_GET_COPY_TO_SITES: 'PROFILE_GET_COPY_TO_SITES',
  PROFILE_GET_COPY_TO_SITES_RESPONE: 'PROFILE_GET_COPY_TO_SITES_RESPONE',
  PROFILE_GET_COPY_TO_SITES_FAILURE: 'PROFILE_GET_COPY_TO_SITES_FAILURE',
}

export const manageGymPermissions = {
  GET: 'MANAGE_GYM_PERMISSIONS_GET',
  GET_RESPONSE: 'MANAGE_GYM_PERMISSIONS_GET_RESPONSE',
  GET_FAIL: 'MANAGE_GYM_PERMISSIONS_GET_FAIL',

  REMOVE: 'MANAGE_GYM_PERMISSIONS_REMOVE',
  REMOVE_RESPONSE: 'MANAGE_GYM_PERMISSIONS_REMOVE_RESPONSE',
  REMOVE_FAIL: 'MANAGE_GYM_PERMISSIONS_REMOVE_FAIL',

  ADD: 'MANAGE_GYM_PERMISSIONS_REMOVE_ADD',
  ADD_RESPONSE: 'MANAGE_GYM_PERMISSIONS_ADD_RESPONSE',
  ADD_FAIL: 'MANAGE_GYM_PERMISSIONS_ADD_FAIL',

  UPDATE: 'MANAGE_GYM_PERMISSIONS_UPDATE',
  UPDATE_RESPONSE: 'MANAGE_GYM_PERMISSIONS_UPDATE_RESPONSE',
  UPDATE_FAIL: 'MANAGE_GYM_PERMISSIONS_UPDATE_FAIL',

  CLEAR: 'MANAGE_GYM_PERMISSIONS_CLEAR',
}

export const myGymStaff = {
  GET: 'MY_GYM_STAFF_GET',
  GET_RESPONSE: 'MY_GYM_STAFF_GET_RESPONSE',
  GET_FAIL: 'MY_GYM_STAFF_GET_FAIL',
  UPDATE: 'MY_GYM_STAFF_UPDATE',

  REMOVE: 'MY_GYM_STAFF_REMOVE',
  REMOVE_RESPONSE: 'MY_GYM_STAFF_REMOVE_RESPONSE',
  REMOVE_FAIL: 'MY_GYM_STAFF_REMOVE_FAIL',

  GET_CLIENTS: 'MY_GYM_STAFF_GET_CLIENTS',
  GET_CLIENTS_RESPONSE: 'MY_GYM_STAFF_GET_CLIENTS_RESPONSE',
  GET_CLIENTS_FAIL: 'MY_GYM_STAFF_GET_CLIENTS_FAIL',
  SEARCH_FOR_CLIENTS: 'MY_GYM_STAFF_SEARCH_FOR_CLIENTS',
  SEARCH_FOR_CLIENTS_RESPONSE: 'MY_GYM_STAFF__SEARCH_FOR_CLIENTS_RESPONSE',
  SEARCH_FOR_CLIENTS_FAILED: 'MY_GYM_STAFF__SEARCH_FOR_CLIENTS_FAILED',
  GET_CLIENTS_STATISTICS: 'MY_GYM_STAFF_GET_CLIENTS_STATISTICS',
  GET_CLIENTS_STATISTICS_RESPONSE: 'MY_GYM_STAFF_GET_CLIENTS_STATISTICS_RESPONSE',
  GET_CLIENTS_STATISTICS_FAIL: 'MY_GYM_STAFF_GET_CLIENTS_STATISTICS_FAIL',

  GET_MEMBERS: 'MY_GYM_STAFF_GET_MEMBERS',
  GET_MEMBERS_RESPONSE: 'MY_GYM_STAFF_GET_MEMBERS_RESPONSE',
  GET_MEMBERS_FAIL: 'MY_GYM_STAFF_GET_MEMBERS_FAIL',
  SEARCH_FOR_MEMBERS: 'MY_GYM_STAFF_SEARCH_FOR_MEMBERS',
  SEARCH_FOR_MEMBERS_RESPONSE: 'MY_GYM_STAFF_SEARCH_FOR_MEMBERS_RESPONSE',
  SEARCH_FOR_MEMBERS_FAILED: 'MY_GYM_STAFF_SEARCH_FOR_MEMBERS_FAILED',
  GET_MEMBERS_STATISTICS: 'MY_GYM_STAFF_GET_MEMBERS_STATISTICS',
  GET_MEMBERS_STATISTICS_RESPONSE: 'MY_GYM_STAFF_GET_MEMBERS_STATISTICS_RESPONSE',
  GET_MEMBERS_STATISTICS_FAIL: 'MY_GYM_STAFF_GET_MEMBERS_STATISTICS_FAIL',

  CLEAR: 'MY_GYM_STAFF_CLEAR',
  SEARCH_FOR_CLEAR: 'MY_GYM_STAFF_SEARCH_FOR_CLEAR',
}

export const advagymStatistics = {
  GET: 'USER_STATISTICS_GET',
  RESPONSE: 'USER_STATISTICS_RESPONSE',
  FAILURE: 'USER_STATISTICS_FAILURE',
  GET_NEW: 'USER_STATISTICS_GET_NEW',
  RESPONSE_NEW: 'USER_STATISTICS_RESPONSE_NEW',
  FAILURE_NEW: 'USER_STATISTICS_FAILURE_NEW',
  SET_DATE: 'USER_STATISTICS_SET_DATE',
}

export const translationTools = {
  ADD_EXERCISE: 'TRANSLATION_TOOLS_ADD_EXERCISE',
  REMOVE_EXERCISE: 'TRANSLATION_TOOLS_REMOVE_EXERCISE',
  CLEAR_EXERCISES: 'TRANSLATION_TOOLS_CLEAR_EXERCISES',
}

export const gallery = {
  GET: 'GALLERY_GET',
  RESPONSE: 'GALLERY_RESPONSE',
  FAIL: 'GALLERY_FAIL',
}

export const tabs = {
  UPDATE: 'USER_TABS_UPDATE'
}

export const actionFooter = {
  SET: 'SET_ACTION_FOOTER',
  CLEAR: 'CLEAR_ACTION_FOOTER',
  UNCLEAR: 'UNCLEAR_ACTION_FOOTER',

  CALL_LEFT: 'CALL_LEFT_',
  REACT_LEFT: 'REACT_LEFT_ACTION_FOOTER',
  CALL_RIGHT: 'CALL_RIGHT_ACTION_FOOTER',
  REACT_RIGHT: 'REACT_RIGHT_ACTION_FOOTER',
  DISABLE_RIGHT: 'DISABLE_RIGHT_ACTION_FOOTER',
  ENABLE_RIGHT: 'ENABLE_RIGHT_ACTION_FOOTER',
  CALL_EXTRA_RIGHT: 'CALL_EXTRA_RIGHT_ACTION_FOOTER',
  REACT_EXTRA_RIGHT: 'REACT_EXTRA_RIGHT_ACTION_FOOTER',
  DISABLE_EXTRA_RIGHT: 'DISABLE_EXTRA_RIGHT_ACTION_FOOTER',
  ENABLE_EXTRA_RIGHT: 'ENABLE_EXTRA_RIGHT_ACTION_FOOTER',
}

export const userDashboardStatistics = {
  GET_GENERAL: 'USER_DASHBOARD_GET_GENERAL',
  GET_GENERAL_RESPONSE: 'USER_DASHBOARD_GET_GENERAL_RESPONSE',
  GET_GENERAL_FAILED: 'USER_DASHBOARD_GET_GENERAL_FAILED',

  GET_WORKOUTS: 'USER_DASHBOARD_GET_WORKOUTS',
  GET_WORKOUTS_RESPONSE: 'USER_DASHBOARD_GET_WORKOUTS_RESPONSE',
  GET_WORKOUTS_FAILED: 'USER_DASHBOARD_GET_WORKOUTS_FAILED',
  GET_WORKOUTS_SELECTION: 'USER_DASHBOARD_GET_WORKOUTS_SELECTION',
  GET_WORKOUTS_SELECTION_RESPONSE: 'USER_DASHBOARD_GET_WORKOUTS_SELECTION_RESPONSE',
  GET_WORKOUTS_SELECTION_FAILED: 'USER_DASHBOARD_GET_WORKOUTS_SELECTION_FAILED',

  GET_PERFORMED_WORKOUTS_RESPONSE: 'USER_DASHBOARD_GET_PERFORMED_WORKOUTS_RESPONSE',
  GET_PERFORMED_EXERCISES: 'USER_DASHBOARD_GET_PERFORMED_EXERCISES',
  GET_PERFORMED_EXERCISES_RESPONSE: 'USER_DASHBOARD_GET_PERFORMED_EXERCISES_RESPONSE',
  GET_PERFORMED_EXERCISES_FAILED: 'USER_DASHBOARD_GET_PERFORMED_EXERCISES_FAILED',
  GET_EXERCISES: 'USER_DASHBOARD_GET_EXERCISES',
  GET_EXERCISES_RESPONSE: 'USER_DASHBOARD_GET_EXERCISES_RESPONSE',
  GET_EXERCISES_FAILED: 'USER_DASHBOARD_GET_EXERCISES_FAILED',
  GET_EXERCISES_SELECTION: 'USER_DASHBOARD_GET_EXERCISES_SELECTION',
  GET_EXERCISES_SELECTION_RESPONSE: 'USER_DASHBOARD_GET_EXERCISES_SELECTION_RESPONSE',
  GET_EXERCISES_SELECTION_FAILED: 'USER_DASHBOARD_GET_EXERCISES_SELECTION_FAILED',

  GET_PERFORMED_CARDIO_EXERCISES: 'USER_DASHBOARD_GET_PERFORMED_CARDIO_EXERCISES',
  GET_PERFORMED_CARDIO_EXERCISES_RESPONSE: 'USER_DASHBOARD_GET_PERFORMED_CARDIO_EXERCISES_RESPONSE',
  GET_PERFORMED_CARDIO_EXERCISES_FAILED: 'USER_DASHBOARD_GET_PERFORMED_CARDIO_EXERCISES_FAILED',
  GET_CARDIO_EXERCISES: 'USER_DASHBOARD_GET_CARDIO_EXERCISES',
  GET_CARDIO_EXERCISES_RESPONSE: 'USER_DASHBOARD_GET_CARDIO_EXERCISES_RESPONSE',
  GET_CARDIO_EXERCISES_FAILED: 'USER_DASHBOARD_GET_CARDIO_EXERCISES_FAILED',
  GET_CARDIO_EXERCISES_SELECTION: 'USER_DASHBOARD_GET_CARDIO_EXERCISES_SELECTION',
  GET_CARDIO_EXERCISES_SELECTION_RESPONSE: 'USER_DASHBOARD_GET_CARDIO_EXERCISES_SELECTION_RESPONSE',
  GET_CARDIO_EXERCISES_SELECTION_FAILED: 'USER_DASHBOARD_GET_CARDIO_EXERCISES_SELECTION_FAILED',

  SET_INTEVAL_SORT: 'USER_DASHBOARD_SET_INTEVAL_SORT',
  SET_WORKOUTS_SORT: 'USER_DASHBOARD_SET_WORKOUTS_SORT',
  SET_EXERCISES_SORT: 'USER_DASHBOARD_SET_EXERCISES_SORT',
  SET_CARDIO_EXERCISES_SORT: 'USER_DASHBOARD_SET_CARDIO_EXERCISES_SORT',
}