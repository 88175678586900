import React from 'react'
import { Grid, Segment, Header, Divider } from 'semantic-ui-react'
import i18next from 'i18next'
import advagym_logo from 'assets/images/advagym_a_white.svg'
import Badge from 'app/protected/roles/common/Badge'

function ClosedBanner(props) {
  return (
    <div style={{ width: '100%', position: 'absolute', top: '30%' }}>
      <Grid>
        <Grid.Row centered>
          <Grid.Column mobile="14" tablet="8" computer="8">
            <Segment style={{ padding: '10px 40px 10px 40px' }}>
              <Grid>
                <Grid.Row>
                  <Grid.Column
                    textAlign="center"
                    mobile="16"
                    tablet="2"
                    computer="2"
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Badge
                        type="coach"
                        label={'TrainerConnect'}
                        icon=""
                        customIcon={advagym_logo}
                        size="big"
                      />
                    </div>
                  </Grid.Column>
                  <Grid.Column mobile="16" only="mobile">
                    <Header
                      style={{ paddingTop: '10px' }}
                      textAlign="center"
                      as="h4"
                    >
                      {i18next.t('important_announcement')}
                    </Header>
                    <div>{i18next.t('important_announcement_text1_short')}</div>
                  </Grid.Column>

                  <Grid.Column only="computer tablet" tablet="14" computer="14">
                    <div style={{ paddingLeft: '10px' }}>
                      <Header as="h4">
                        {i18next.t('important_announcement')}
                      </Header>
                      <div>
                        {i18next.t('important_announcement_text1_short')}
                      </div>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

export default ClosedBanner
